import { SERVER_API_URL as ENV_SERVER_API_URL } from "./constants";

//  Method constants
export const POST = "POST";
export const PUT = "PUT";
export const PATCH = "PATCH";
export const GET = "GET";
export const DELETE = "DELETE";

// Entity constants

export const LOGIN = {
  ADMIN_USER_LOGIN: `/admin/login`,
  REGISTER_ADMIN: `/admin`,
  ADMIN_USER_PASSWORD_CHANGE: `/admin/change-password`,
  ADMIN_FORGOT_PASSWORD: `/admin/forgot-password`,
  VERIFY_OTP: `/admin/verify-otp`,
  COACH_USER_LOGIN: `/customer/coach-login`,
  COACH_FORGOT_PASSWORD: `/customer/coach-forgot-password`,
  COACH_VERIFY_OTP: `/customer/verify-coach-otp`,
  COACH_USER_PASSWORD_CHANGE: `/customer/coach/change-password`,
};

export const CLUBS = {
  LIST_CLUBS: `/club`,
  LIST_CLUBS_LEAN: `/club/lean`,
  ADD_CLUB: `/club`,
  FEATURE_LIST: `/feature`,
  FETCH_CITIES: `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=`,
  PLACE_DETAIL: `https://maps.googleapis.com/maps/api/place/details/json?placeid=`,
  ONE_CLUB_DETAIL: `/club/`,
  STATUS_UPDATE: `/club/`,
  COURT_STATUS_UPDATE: `/club/courts/`,
  IMG_UPLOAD: `${ENV_SERVER_API_URL}/upload/s3`,
  COACH_CLUBS_LIST: `/coach/club/list`,
};

export const DASHBOARD = {
  GET_DASHBOARD: `/dashboard`,
  GET_DASHBOARD_TOURNAMENTS: `/tournament/dashboard/teams`,
  SEND_BROADCAST_MESSAGE: "/notification",
};

export const USER_MANAGEMENT = {
  API_END_POINTS: {
    ADD_ADMIN_USER_DETAILS: `/admin`,
    UPDATE_ADMIN_USER_DETAILS: `/admin`,
    GET_ADMIN_DETAILS: `/admin/profile`,
    GET_CUSTOMER_DETAILS: `/customer/profile`,
    GET_ALL_ADMIN_USERS: `/admin`,
    DELETE_ADMIN_USER: `/admin`,
    GET_AN_ADMIN_USER_DETAIL: `/admin/`,
    STATUS_UPDATE: `/admin/`,
    ROLE_LIST: `/role/admin`,
    GET_ALL_END_USERS: `/customer`,
    REGISTER_CUSTOMER: `/customer/login`,
    USER_FORGOT_PASSWORD: `/customer/forgot-password`,
    ADMIN_FORGOT_PASSWORD: `/admin/forgot-password`,
    GET_END_USER_PROFILE: `/customer/`,
    CHECK_PHONE_VALIDITY: `/customer/mobile-number/check`,
  },
};

export const CONSOLIDATED_USERS = {
  GET_ALL_USERS: `/all-users`,
};

// Apparels
export const EXTRAS_MANAGEMENT = {
  ADD_APPARELS_DETAILS: `/product`,
  UPDATE_APPARELS_DETAILS: `/product`,
  GET_APPAREL_DETAILS: `/product`,
  GET_ALL_APPARELS: `/product`,
  GET_ALL_PURCHASES: "/productorder",
  ADD_PURCHASE: `/productorder`,
  UPDATE_PURCHASE: `/productorder`,
  CANCEL_PURCHASE: `/productorder/cancel`,
  ADD_SERVICE: `/product/service`,
  UPDATE_SERVICE: `/product/service`,
  GET_SERVICE: `/product/service`,
};

// Bookings
export const BOOKING_MANAGEMENT = {
  API_END_POINTS: {
    UPDATE_BOOKING_DETAILS: `/booking`,
    GET_BOOKING_DETAILS: `/booking`,
    GET_ALL_BOOKINGS: `/booking`,
    GET_BOOKING_DETAIL: `/booking`,
    STATUS_UPDATE: `/booking`,
    SLOT_AVAILABILITY: `/booking-available/check`,
    COURT_AVAILABILITY: `/booking-available/courtsAvailability`,
    COURT_BOOKING: `/booking/admin`,
    ADD_NOTES: `/booking`,
    DELETE_NOTES: `/booking/deleteNote`,
    PARTICIPANTS_UPDATE: `/booking/admin`,
    MATCH_RESULTS_UPDATE: `/booking/admin`,
    RECURRING_BOOKING_DATE_CHECK: `/booking/recurring/date-check`,
    PAYMENT_UPDATE: `/booking/payment-update`,
    MUTLIPLE_BOOKINGS_CANCEL: `/booking/cancel`,
    COURT_COACH_BOOKINGS: `/booking/court-coach/list`,
    COURT_COACH_AVAILABLE_SLOTS: `/booking-available/available-slots`,
    PAYMENT_STATUS_UPDATE: `/transaction/status-update`,
    BLOCK_SLOTS: `/booking-available`,
    ACTIVATE_PARTICIPANT: `/booking/admin`,
  },
};

// Chat Messages
export const CHAT_MESSAGES = {
  ADD_CONVERSATION: `/conversation`,
  ADD_MESSAGE: `/conversation/message`,
  GET_END_USERS: `/customer`,
  GET_CONVERSATIONS: `/conversation`,
  GET_ALL_MESSAGE: `/conversation/message`,
  DELETE_MESSAGE: `/conversation/message`,
};

// Coaching
export const COACHING_MANAGEMENT = {
  ADD_COACHING_DETAILS: `/coach`,
  UPDATE_COACHING_DETAILS: `/admin`,
  GET_COACHING_DETAILS: `/coach`,
  GET_ALL_COACHINGS: `/coach`,
  GET_ALL_TAGS: `/tag`,
};

// Coaching Sessions
export const COACHING_SESSIONS_MANAGEMENT = {
  COACH_AVAILABILITY_CHECK: `/booking/coach/availability-check`,
  GET_ALL_SESSIONS: `/booking/coaching-sessions`,
  ADD_COACHING_SESSION: `/booking/coach`,
  ADD_COACHING_SESSION_DETAIL: `/booking`,
  GET_ALL_COACH_PORTAL_SESSIONS: `/booking/my-coaching-sessions`,
  COACH_PORTAL_ADD_PLAYER: `/booking/coach/add-participant`,
  COACH_PORTAL_UPDATE_COACHING_SESSION: `/booking/coach`,
  COACH_PORTAL_CANCEL_COACHING_SESSION: `/booking/coach`,
  COACH_PORTAL_REMOVE_SINGLE_PLAYER: `/booking/coach`,
  COACH_PORTAL_GET_COACHING_SESSION_DETAIL: `/booking`,
  GET_ALL_COURT_TOURNAMENTS: `/tournament/dashboard`,
  UPDATE_PLAYER_DATA: `/booking/coach`,
  OVERVIEW_COACHING_SESSION: `/booking/coaching-session`,
  BOOKING: `/booking`,
};

// Coaching Products
export const COACHING_PRODUCTS_MANAGEMENT = {
  COACH_AVAILABILITY_CHECK: `/coachingproduct`,
  GET_ALL_PRODUCTS: `/coachingproduct`,
  GET_PRODUCTS_DETAILS: `/coachingproduct`,
  ADD_COACHING_PRODUCTS: `/coachingproduct`,
};

// Coupons
export const COUPONS_MANAGEMENT = {
  UPDATE_COUPON_DETAILS: `/coupon`,
  GET_COUPON_DETAILS: `/coupon`,
  GET_ALL_COUPONS: `/coupon`,
  VERIFY_COUPON: `/coupon/verify`,
};

// FEED
// TODO: remove this, as this is replaced by FEED_ITEMS_MANAGEMENT
export const FEED_MANAGEMENT = {
  API_END_POINTS: {
    UPDATE_FEED_DETAILS: `/feed`,
    GET_FEED_DETAILS: `/feed`,
    GET_ALL_FEEDS: `/feed`,
    DELETE_FEED: `/feed`,
    DELETE_COMMENT: `/comment`,
    ADD_COMMENT: `/comment`,
    CREATE_FEED: `/feed/admin`,
    UPDATE_FEED: `/feed`,
    ADD_LIKE: `/like`,
    GET_ALL_COMMENTS: `/comment`,
  },
};

// Transactions
export const TRANSACTIONS_MANAGEMENT = {
  GET_ALL_TRANSACTIONS: `/transaction/report`,
};


export const FEED_ITEMS_MANAGEMENT = {
  API_END_POINTS: {
    GET_ALL_FEEDS: `/feed`,
    GET_FEED_DETAILS: `/feed`,
    CREATE_FEED: `/feed`,
    UPDATE_FEED_DETAILS: `/feed`,
    ADD_LIKE: `/feed`,
    GET_ALL_COMMENTS: `/comments`,
    ADD_COMMENT: `/comments`,
    DELETE_COMMENT: `/comments`,
    DELETE_FEED: `/feed`,
    LOCALITY_LIST: `/customer/locality/list`,
  },
};

export const MEDIA_UPLOAD_MANAGEMENT = {
  API_END_POINTS: {
    GET_PRE_SIGNED_URL: `/upload/getuploadurl`,
  },
};

// Subcriptions
export const MEMBERSHIPS_MANAGEMENT = {
  COACH_AVAILABILITY_CHECK: `/membership`,
  GET_ALL_SUBSCRIPTIONS: `/membership`,
  GET_SUBSCRIPTION_DETAILS: `/membership`,
  ADD_COACHING_SUBSCRIPTIONS: `/membership`,
  GET_BENEFIT: "/benefit",
  ADD_USER_MEMBERSHIP: `/customer/add-membership`,
  UPDATE_USER_MEMBERSHIP: `/customer/update-membership`,
  GET_ALL_USER_MEMBERSHIPS: `/customer`,
};

// Configurations
export const CONFIGURATIONS_MANAGEMENT = {
  GET_ALL_CONFIGURATIONS: `/setting`,
  UPDATE_CONFIGURATIONS: `/setting`,
};

// Tags
export const TAGS_MANAGEMENT = {
  GET_ALL_TAGS: `/tag`,
  GET_TAG_DETAILS: `/tag`,
  ADD_TAG: `/tag`,
  DELETE_TAG: "/tag",
};

// Tournaments
export const TOURNAMENTS_MANAGEMENT = {
  API_END_POINTS: {
    UPDATE_TOURNAMENT_DETAILS: `/tournament/admin`,
    GET_TOURNAMENT_DETAILS: `/tournament`,
    GET_ALL_TOURNAMENTS: `/tournament/admin`,
    STATUS_UPDATE: `/tournament/status`,
    UPDATE_LICENSE_NUMBER: `/tournament/updateLicense`,
    ADD_TEAM_PLAYER: `/tournament`,
    ADD_TEAM: `/tournament`,
    ADD_TOURNAMENT_DETAILS: `/tournament`,
    UPDATE_TOURNAMENT: `/tournament/update`,
    PUBLISH_TOURNAMENT: `/tournament`,
    CANCEL_TOURNAMENT: `/tournament`,
    VERIFY_LICENSE_NUMBER: `/tournament/verifylicense`,
    APPROVE_DECLINE_PLAYER: `/tournament/playerrequest`,
    NOTIFY_USER: `/tournament`,
  },
};

const date = new Date();
export const MONTH = date.getUTCMonth() + 1; // months from 1-12
export const DAY = date.getUTCDate();
export const YEAR = date.getUTCFullYear();

const getWeek = (dt: Date) => {
  const onejan: any = new Date(dt.getFullYear(), 0, 1);
  const today: any = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());
  const dayOfYear = (today - onejan + 86400000) / 86400000;
  return Math.ceil(dayOfYear / 7);
};

export const WEEK = getWeek(date);

export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
