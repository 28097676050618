// src/redux/bookingsReducer.ts
import { createSlice } from "@reduxjs/toolkit";

const transactionsSlice = createSlice({
  name: "transactions",
  initialState: { data: [], loading: false, error: null },
  reducers: {
    transactionsRequested: (state) => {
      state.loading = true;
      state.error = null;
    },
    transactionsReceived: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    transactionsRequestFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    transactionsReset: (state) => {
      state.loading = false;
      state.data = [];
    },
  },
});

export const {
  transactionsRequested,
  transactionsReceived,
  transactionsRequestFailed,
  transactionsReset
} = transactionsSlice.actions;

export default transactionsSlice.reducer;
