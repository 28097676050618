import React, { Suspense, useState, useEffect, useRef } from "react";
import {
  Modal,
  Slide,
  Paper,
  Box,
  IconButton,
  Typography,
  Tabs,
  Tab,
  Divider,
  Grid,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { Form, Formik, FormikProps } from "formik";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  IBooking,
  defaultBookingValue,
  IPlayers,
  IMatchResults,
  defaultMatchResultsValue,
  IParticipants,
} from "../../../model/bookings-model";
import { fetchEndUsers } from "app/entities/Users/usersApiService";
import { PUT, BOOKING_MANAGEMENT } from "../../../config/API.constants";
import { HANDLE_ERROR, HTTP_CALL } from "../../../config/http";
import { showToast } from "../../../shared/util/toastHelper";
import {
  ActionTypes,
  PaidOptions,
  BookingPaymentType,
} from "../../../config/constants";
import dayjs from "dayjs";
import { calculateEndTime } from "../../../shared/util/calculateEndTime";
import { AppDispatch } from "app/redux/store";
import {
  addPlayerBooking,
  getSingleBooking,
} from "app/entities/Bookings/bookingsApiService";
import ConfirmDialog from "app/shared/components/ConfirmDialog"; 
import { SubmitButton } from "app/shared/molecules";

const EditBookingInformationTab = React.lazy(
  () => import("./Tabs/EditBookingInformationTab")
);
const PlayersTab = React.lazy(() => import("./Tabs/PlayersTab"));
const MatchResultsTab = React.lazy(() => import("./Tabs/MatchResultsTab"));

interface EditBookingModalProps {
  open: boolean;
  onClose: () => void;
  formData?: any;
  action?: any;
  refreshBookingGrid?: any;
  page?: any;
  refresh?: any;
  setRefresh?: any;
  formProps?: any;
  refreshSingleBookingData?:any;
}

const BoldTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold", // Set the font weight to bold
}));

const brownButtonStyle = {
  backgroundColor: "#333333",
  color: "white", // Text color
};

const EditBookingModalForm: React.FC<EditBookingModalProps> = ({
  open,
  onClose,
  formData,
  action,
  refreshBookingGrid,
  page,
  refresh,
  setRefresh,
  formProps,
  refreshSingleBookingData
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [activeTab, setActiveTab] = React.useState(0);
  const formikRef = useRef<FormikProps<any>>(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
	const [isAddPlayerFormVisible, setAddPlayerFormVisible] = useState(false);


  const [bookingInformationData, setBookingInformationData] =
    useState<IBooking>(formData);

  const [playersData, setPlayersData] = useState<IPlayers>({
    participants: formData?.participants?.filter(
      (x: any) => x?.status !== "cancelled"
    ),
  });

  const [matchResultsData, setMatchResultsData] = useState<IMatchResults>(
    defaultMatchResultsValue
  );

  const [usersList, setUsersList] = useState([]);
  const [bookingType, setBookingType] = useState("");
  const [parentAmountPrice, setParentAmountPrice] = useState(0);

  // Function to update parentAmountPrice
  const handleAmountPriceChange = (newAmountPrice: any) => {
    setParentAmountPrice(newAmountPrice);
  };

  // Function to update booking type
  const updateBookingType = (type: any) => {
    setBookingType(type);
  };

  // Set all the form data used for building the request object to the API finally
  // For bookingInformationData
  // For the first useEffect hook
  useEffect(() => {
    if (activeTab === 0) {
      setBookingInformationData({
        ...bookingInformationData,
      });
    }
    return () => {
      // Cleanup logic for the first useEffect hook
    };
  }, [activeTab]);

  // For the second useEffect hook
  useEffect(() => {
    if (activeTab === 1) {
      setPlayersData({
        ...playersData,
      });
    }
    return () => {
      // Cleanup logic for the second useEffect hook
    };
  }, [activeTab]);

  // For the third useEffect hook
  useEffect(() => {
    if (activeTab === 2) {
      setMatchResultsData({
        ...matchResultsData,
      });
    }
    return () => {
      // Cleanup logic for the third useEffect hook
    };
  }, [activeTab]);

  // For the fourth useEffect hook
  useEffect(() => {
    const fetchUsersData = async (page: any, limit: any) => {
      try {
        const userApiData = await fetchEndUsers(page, limit, true); // Call the data fetching function
        setUsersList(userApiData);
      } catch (error) {
        // Handle errors if necessary
      }
    };

    fetchUsersData(0, 1000);

    // Cleanup function for the fourth useEffect hook
    return () => {
      // Cleanup logic for the fourth useEffect hook
    };
  }, []);

  // For the fifth useEffect hook
  useEffect(() => {
    if (formData?._id && action === ActionTypes?.EDIT) {
      if (activeTab === 0) {
        setBookingInformationData(formData);
      } else if (activeTab === 1) {
        setPlayersData({
          participants: formData?.participants?.filter(
            (x: any) => x?.status !== "cancelled"
          ),
        });
      } else if (activeTab === 2) {
        setMatchResultsData({
          noOfMatches: formData?.matchResults?.noOfMatches || 3,
          winner: formData?.matchResults?.winner || '',
          matches: formData?.matchResults?.matches || [{a: 0, b: 0, winner: ""}, {a: 0, b: 0, winner: ""}, {a: 0, b: 0, winner: ""}],
        });
      }
    }

    // Cleanup function for the fifth useEffect hook
    return () => {
      // Cleanup logic for the fifth useEffect hook
    };
  }, [formData, activeTab, action]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  /**
   * Checks form data for the valid data.
   *
   * @function
   * @returns {Boolean} Boolean value of hasValues whether the validity is succcess or not
   */

  const handleStepData = (objectKey: string) => (e: any) => {
    if (e.target) {
      if (activeTab === 0) {
        setBookingInformationData({
          ...bookingInformationData,
          [objectKey]: e.target.value,
        });
      } else if (activeTab === 1) {
        setPlayersData((prevData) => ({
          ...prevData,
          participants: {
            ...prevData.participants,
            [objectKey]: e.target.value,
          },
        }));
      }
    }
  };

    /**
   * Handle close on button click
   *
   * @function
   * @returns void
   */
    const handleCloseModal = (event?: any, reason?: any) => {
      if (formikRef?.current?.dirty) {
        // Show confirmation dialog if there are unsaved changes
        setConfirmOpen(true);
      } else {
        onClose();
        setActiveTab(0);
        if (page !== "bookingDetail") {
          refreshBookingGrid(0, 100);
        } else {
          setRefresh(true);
          // Call the callback function to trigger the refresh of the detail page
          if (refresh) {
            refresh();
          }
        }
      }
    };
  
      // Handle close confirmation dialog
      const handleCancelClose = () => {
        setConfirmOpen(false);
      };
    
      // Handle confirm close action
      const handleConfirmClose = () => {
        setConfirmOpen(false);
        onClose();
      };
  

  const handleParticipantsUpdate = (updatedParticipants: IParticipants[]) => {
    setPlayersData({ ...playersData, participants: updatedParticipants });
  };

  const handleMatchResultsUpdate = (updatedMatchResults: IMatchResults[]) => {
    setMatchResultsData(updatedMatchResults[0]);
  };

  const addSinglePlayer = async (values?: any, callback?: any) => {
    let payemtStatus = formData?.typeOfBooking === 'spot' &&  formData?.pricePerParticipant > parseFloat(values?.paidAmount)   ? "pending": 'completed';
    
    if (
      values?.paymentType ===
        BookingPaymentType?.GYMLIB ||
      values?.paymentType ===
        BookingPaymentType?.SUBSCRIPTION
    ) {
      payemtStatus = 'completed';
    }

    let payload =  {
      ...values,
      bookingId: formData?._id,
      // paidAmount: parseFloat(
      //   playersData?.participants?.participants[
      //     playersData?.participants?.participants?.length - 1
      //   ]?.paidAmount || 0
      // ),
      paidAmount: values?.paidAmount ? parseFloat(values?.paidAmount): 0,
      paymentStatus: payemtStatus,
      isUpdateLocalData: true,
      isOrganiser: false,
      status: "active",
    };

    if(payload?.selectedUserObj){
      delete payload.selectedUserObj;
    }

    dispatch(
      addPlayerBooking(
        payload,
        (data: any, error:any) => {
          // setRefresh(true);
          // onClose();
          if (data && typeof refreshSingleBookingData === 'function') {
            setAddPlayerFormVisible(false);
            refreshSingleBookingData();
          }
          if(data && typeof callback === 'function') {
            callback(data);
          }
          if(error && typeof callback === 'function') {
            callback('', error);
          }
          // dispatch(
          //   getSingleBooking(formData?._id, (newData: any) => {
          //     formProps.setValues({
          //       ...formProps.values,
          //       ...newData,
          //     });
          //     setRefresh(true);
          //     onClose();
          //   })
          // );
        },
        "Player added successfully"
      )
    );
  };

  /**
   * Handle update
   *
   * @function
   * @returns void
   */
  const handleUpdateBooking = async (
    apiEndpoint: string,
    payloadData: any,
    successMessage: string,
    actions?:any
  ) => {
    try {
      actions.setSubmitting(true);
      const response = await HTTP_CALL(apiEndpoint, PUT, "", payloadData);

      const responseData = response as {
        data: { statusCode: number; data: any; message: string };
      };

      if (responseData.data.statusCode === 200) {
        if (responseData.data.data !== null) {
          showToast(successMessage, "Success", {
            position: toast.POSITION.TOP_RIGHT,
          });
          actions.setSubmitting(false);
          if(refreshBookingGrid)
            refreshBookingGrid(0, 100);
          onClose();
        }
      } else {
        showToast(responseData?.data?.message, "Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
        actions.setSubmitting(false);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
      actions.setSubmitting(false);
    }
  };

  /**
   * Handle submit with all the form data collected in each step
   *
   * @function
   * @returns void
   */
  const handleSubmit = async (values?: any, actions?: any, activeTab?: any) => {
    actions.setSubmitting(true);
    const participantsUpdateBookingAPI =
      BOOKING_MANAGEMENT.API_END_POINTS.PARTICIPANTS_UPDATE;
    const matchResultsUpdateBookingAPI =
      BOOKING_MANAGEMENT.API_END_POINTS.MATCH_RESULTS_UPDATE;
    const bookingInformationUpdateBookingAPI =
      BOOKING_MANAGEMENT.API_END_POINTS.UPDATE_BOOKING_DETAILS;

    // Adjust the payload based on the activeTab
    let payloadData = values; // Default to values

    if (activeTab === 1) {

      const hasEmptyDdCode = values?.participants?.some(
        (participant:any) => participant?.paymentType === 'gymlib' && (participant.gymlibCode === '' || participant.gymlibCode === undefined)
      );

      if(hasEmptyDdCode) {
        showToast(
          "Gymlib Code Required",
          "Error",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        actions.setSubmitting(false);
        return false;
      }

      // For Participants Tab (activeTab === 1), modify payloadData for participants
      payloadData = {
        participants: values?.participants?.map((participant: any) => {
            let paymentStatus = 'completed';
            let paidAmountValue = participant?.paidAmount   
          	? parseFloat(Number(participant.paidAmount).toFixed(2)) 
          	: 0;

          	if(participant?.paymentType === BookingPaymentType.SUBSCRIPTION || values?.paymentType === BookingPaymentType.GYMLIB) {
          		paymentStatus = 'completed';
          	} else {
          		if(values?.typeOfBooking === 'spot' && values?.pricePerParticipant > paidAmountValue)
          			paymentStatus = 'pending';
          		else if(values?.typeOfBooking === 'court' && values?.balanceAmount !== 0)
          			paymentStatus = 'pending';
          	}


          return {
            ...participant,
            paymentStatus,
            status: participant?.status || "active", // Set status to "pending"
            paidAmount: parseFloat(participant?.paidAmount),
          }
        }),
      };
    }

    const serviceCost = values?.services?.reduce(
      (accumulator: any, currentValue: any) => accumulator + currentValue.price,
      0
    );

    // Include isPublic field in the payload data
    values.isPublic = bookingType === "private" || values?.type === "private"  ? false : true;
    values.matchType = formData?.matchType
      ? formData?.matchType
      : values?.matchType;
    const formattedDate = dayjs.utc(values.date).format("YYYY-MM-DD");

    if (activeTab === 0 || activeTab === undefined) {
      values.startTime = `${formattedDate} ${values.slots[0]}`;
      values.endTime = `${formattedDate} ${calculateEndTime(
        values.slots[0],
        values.bookingMinutes
      )}`;

      // values.startTime = moment(`${formattedDate} ${values.slots[0]}`).format(
      //   "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
      // );
      // values.endTime = moment(
      //   `${formattedDate} ${calculateEndTime(
      //     values.slots[0],
      //     values.bookingMinutes
      //   )}`
      // ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

      values.balanceAmount = values?.balanceAmount;
      values.gymlibCode =
        values?.paymentType === "gymlib" ? values?.gymlibCode : 0;

      // values.totalAmount =
      //   values?.typeOfBooking === "court"
      //     ? parseFloat(values?.balanceAmount + parseFloat(values?.paidAmount))
      //     : values?.balanceAmount + parseFloat(values?.paidAmount);

      //values.totalAmount = values?.initialAmount;

      values.totalAmount =
        values?.typeOfBooking === "court"
          ? values?.initialAmount
          : parseFloat(values?.pricePerParticipant||0) * values?.playersAllowed;

      values.balanceAmount =
        values?.paymentType === BookingPaymentType?.SUBSCRIPTION
          ? 0
          : values?.balanceAmount
          ? parseFloat(values?.balanceAmount)
          : "";

      values.pricePerParticipant =
        values?.typeOfBooking === "court" &&
        parseFloat(values?.balanceAmount) !== 0
          ? parseFloat(values?.balanceAmount + parseFloat(values?.paidAmount))
          : values?.typeOfBooking === "court" &&
            parseFloat(values?.balanceAmount) === 0
          ? 0
          : values?.typeOfBooking === "spot" &&
            parseFloat(values?.paidAmount) * values?.playersAllowed -
              parseFloat(values?.paidAmount) ===
              0
          ? 0
          : values?.balanceAmount + parseFloat(values?.paidAmount);

      values.paymentStatus =
        formData?.paymentType === "gymlib" ||
        values?.paymentStatus === PaidOptions?.PAID
          ? "completed"
          : formData?.paymentType === BookingPaymentType?.SUBSCRIPTION
          ? "completed"
          : values?.typeOfBooking === "court" &&
            (formData?.balanceAmount === 0 || values?.balanceAmount === 0)
          ? "completed"
          : values?.typeOfBooking === "spot" &&
            values?.totalAmount === values?.paidAmount
          ? "completed"
          : values?.paymentStatus === PaidOptions?.UNPAID
          ? "pending"
          : values?.balanceAmount !== 0
          ? "pending"
          : formData?.paymentStatus;
      values.typeOfBooking = values?.typeOfBooking || formData?.typeOfBooking;
      values.customBookingPrice = values?.customBookingPrice;

      if (values?.services?.length > 0 && serviceCost > values?.paidAmount) {
        showToast(
          "A minimum service fee must be paid when creating a booking.",
          "Error",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        actions.setSubmitting(false);
      } else if (
        ![30, 60, 90, 120].includes(values?.bookingMinutes) &&
        !values?.customBookingPrice
      ) {
        showToast("Enter custom price", "Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
        actions.setSubmitting(false);
      } else {
        values.paidAmount = values?.paidAmount
          ? serviceCost > 0
            ? values?.paidAmount - serviceCost
            : parseFloat(values?.paidAmount)
          : 0;

        handleUpdateBooking(
          bookingInformationUpdateBookingAPI + "/" + formData?._id + "/admin",
          { ...values },
          t("bookingUpdatedSuccessfully"),
          actions
        );
      }
    } else if (activeTab === 1) {
      let cancelledParticipants = formData?.participants?.filter((x:any)=> x?.status !== 'active')||[];
      let updatedParticipants = [...payloadData?.participants||[], ...cancelledParticipants];
      handleUpdateBooking(
        participantsUpdateBookingAPI + "/" + formData?._id + "/participants",
        updatedParticipants,
        t("bookingUpdatedSuccessfully"),
        actions
      );
    } else if (activeTab === 2) {
      handleUpdateBooking(
        matchResultsUpdateBookingAPI + "/" + formData?._id + "/results",
        {...matchResultsData, matchResultStatus: formData?.matchResultStatus || 'pending' },
        t("bookingUpdatedSuccessfully"),
        actions
      );
    }
  };

  /**
   * Render the forms on each step
   *
   * @function
   * @returns void
   */
  const renderStep = (step: number, formProps: any) => {
    switch (step) {
      case 0:
        return (
          <EditBookingInformationTab
            open={open}
            onClose={handleCloseModal}
            formData={formData}
            refreshBookingGrid={refreshBookingGrid}
            action={action}
            formProps={formProps}
            handleChange={handleStepData}
            onUpdateBookingType={updateBookingType}
            onAmountPriceChange={handleAmountPriceChange}
          />
        );
      case 1:
        return (
          <PlayersTab
            formProps={formProps}
            usersList={usersList}
            addSinglePlayer={addSinglePlayer}
            updateFormData={handleParticipantsUpdate}
            setActiveTab={(tabIndex: number) => {
              // Navigate to tab 0 first, and then back to tab 1 after deletion
              setActiveTab(0); // Navigate to tab 0
              setTimeout(() => {
                setActiveTab(tabIndex); // Navigate back to the desired tab
              }, 900); // Adjust the delay as needed
            }}
            setAddPlayerFormVisible={setAddPlayerFormVisible}
            isAddPlayerFormVisible={isAddPlayerFormVisible}
            formData={formData}
            refreshSingleBookingData={()=>{
              refreshSingleBookingData()}
            }
          />
        );
      case 2:
        return (
          <MatchResultsTab
            matchResultsDataFromParent={formData}
            matchResultsData={matchResultsData}
            setMatchResultsData={setMatchResultsData}
            updateMatchResults={handleMatchResultsUpdate}
            formProps={formProps}
          />
        );
      default:
        return null;
    }
  };

  return (
    <><Modal
      open={open}
      onClose={handleCloseModal}
      closeAfterTransition
      slotProps={{
        backdrop: {
          style: {
            backgroundColor:
              action === ActionTypes?.CREATE
                ? "none"
                : page !== "bookingDetail"
                ? "rgba(0,0,0,0.1)"
                : "rgba(0,0,0,0.5)",
          },
        },
      }}
    >
      <Slide in={open} direction="left">
        <Paper
          style={{
            position: "absolute",
            right: "0",
            transform: "translateY(-50%)",
            width: "35%",
            padding: "20px",
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Formik
            innerRef={formikRef}
            initialValues={
              formData?._id
                ? {
                    ...formData,
                    type: formData?.isPublic === false ? "private" : "public",
                    participants: formData?.participants?.filter(
                      (x: any) => x?.status !== "cancelled"
                    ),
                    initialAmount:
                      formData?.paidAmount + formData?.balanceAmount,
                    amountWithOutServicePrice:
                      formData?.paidAmount + formData?.balanceAmount,
                    amountToPay:
                      formData?.paidAmount +
                      formData?.balanceAmount +
                      formData?.services?.reduce(
                        (accumulator: any, currentValue: any) =>
                          accumulator + currentValue.price,
                        0
                      ),
                    paidAmount:
                      formData?.paidAmount +
                      formData?.services?.reduce(
                        (accumulator: any, currentValue: any) =>
                          accumulator + currentValue.price,
                        0
                      ),
                  }
                : defaultBookingValue
            }
            onSubmit={(values, actions) => {
              handleSubmit(values, actions, activeTab);
            }}
            enableReinitialize
            /* Not allowing to submit the values in the player tab when its enabled - need fix */
            // validationSchema={editBookingValidationSchema(activeTab)}
          >
            {(formProps: any) => {
              let alreadyAddPlayerFormOpen =
                formProps?.values?.participants?.filter(
                  (x: any) => !x?.createdAt && x?.userStatus !== "cancelled"
                );

              return (
                <>
                  <Form>
                    <Box>
                      {/* Content of the sliding modal */}
                      <BoldTypography variant="h6">
                        {" "}
                        {action === ActionTypes?.CREATE
                          ? t("addANewBooking")
                          : t("editBooking")}
                      </BoldTypography>
                      <Divider />
                      <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleCloseModal}
                        aria-label="close"
                        sx={{
                          position: "absolute",
                          top: "15px",
                          right: "24px",
                          cursor: "pointer",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                      <Tabs value={activeTab} onChange={handleTabChange}>
                        <Tab label={t("bookingInformation")} />
                        <Tab label={t("players")} />
                        <Tab label={t("matchResults")} />
                      </Tabs>
                      <Suspense fallback={<div>Loading...</div>}>
                        {renderStep(activeTab, formProps)}
                      </Suspense>

                      <Grid
                        item
                        xs={12}
                        container
                        justifyContent="flex-end"
                        style={{ marginTop: "15px" }}
                      >
                        {((activeTab === 1 && !isAddPlayerFormVisible) || activeTab !== 1) && ( 
                        <SubmitButton
                          type="submit"
                          disabled={formProps?.isSubmitting}
                        >
                          {t("saveBookingChanges")}
                        </SubmitButton>
                        )}
                      </Grid>
                    </Box>
                  </Form>
                </>
              );
            }}
          </Formik>
        </Paper>
      </Slide>
    </Modal>
        {/* Confirmation dialog for unsaved changes */}
        <ConfirmDialog
    open={confirmOpen}
    onClose={handleCancelClose}
    onConfirm={handleConfirmClose}
  />
    
    </>
  );
};

export default EditBookingModalForm;
