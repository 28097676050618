import {
  ActionTypes,
  adminUsers,
  endUsers,
  booking,
  club,
  coachingManagement,
  tournaments,
  membershipProducts,
  userMemberships,
  tags,
  apparelList,
  userPurchaseList,
  coachingProducts,
  coupons,
  services,
  sessions,
} from "../../config/constants";
import AddClubModalForm from "../../entities/Clubs/modals/AddClubModalForm";
import AddCoachingModalForm from "../../entities/Coaching/modals/AddCoachingModalForm";
import AddNewUserModalForm from "../../entities/Users/modals/AddNewUserModalForm";
import AddNewBookingModalForm from "../../entities/Bookings/modals/AddNewBookingModalForm";
import AddTournamentModalForm from "app/entities/Tournaments/modals/AddTournamentModalForm";
import AddProductModalForm from "app/entities/CoachProducts/modals/AddProductModalForm";
import AddExtraProductModalForm from "app/entities/Extras/modals/AddExtraProductModalForm";
import AddUserPurchaseModalForm from "app/entities/Extras/modals/AddUserPurchaseModalForm";
import AddServiceModalForm from "app/entities/Extras/modals/AddServiceModalForm";
import AddMembershipProductModalForm from "app/entities/Memberships/modals/AddMembershipProductModalForm";
import AddUserMembershipModalForm from "app/entities/Memberships/modals/AddUserMembershipModalForm";
import AddTagModalForm from "app/entities/Tags/modals/AddTagModalForm";
import AddCouponModalForm from "app/entities/Coupons/modals/AddCouponModalForm";
import AddSessionModalForm from "app/entities/Sessions/modals/AddSessionModalForm";

const renderPageForm = (containerProps?: any) => {
  const {
    features,
    menuName,
    isModalOpen,
    handleCloseModal,
    type,
    refreshGrid,
    updateGridData,
    setUpdateGridData,
    searchParams,
    isOverviewPage,
    formData, // used add booking module from overview page
    selectedClub
  } = containerProps;

  switch (menuName) {
    case coachingManagement:
      return (
        <AddCoachingModalForm
          open={isModalOpen}
          onClose={handleCloseModal}
          type={type}
          action={ActionTypes?.CREATE}
          formData=""
          refreshClubsGrid={refreshGrid}
          searchParams={searchParams}
        />
      );
    case club:
      return (
        <AddClubModalForm
          open={isModalOpen}
          onClose={handleCloseModal}
          features={features}
          type={type}
          action={ActionTypes?.CREATE}
          formData=""
          refreshClubsGrid={handleCloseModal}
        />
      );
    case adminUsers: case endUsers:
      return (
        <AddNewUserModalForm
          open={isModalOpen}
          userType={menuName}
          onClose={handleCloseModal}
          action={ActionTypes?.CREATE}
          formData=""
          updateGridData={updateGridData}
          setUpdateGridData={setUpdateGridData}
          refreshGrid={refreshGrid}
        />
      );
    case booking:
      return (
        <AddNewBookingModalForm
          open={isModalOpen}
          onClose={handleCloseModal}
          action={ActionTypes?.CREATE}
          formData={formData}
          refreshBookingGrid={refreshGrid}
          isOverviewPage={isOverviewPage}
        />
      );
    case tournaments:
      return (
        <AddTournamentModalForm
          open={isModalOpen}
          onClose={handleCloseModal}
          action={ActionTypes?.CREATE}
          formData={formData}
          isOverviewPage={isOverviewPage}
          refreshGrid={refreshGrid}
        />
      );
    case coachingProducts:
      return (
        <AddProductModalForm
          open={isModalOpen}
          onClose={handleCloseModal}
          action={ActionTypes?.CREATE}
          formData=""
        />
      );
    case apparelList:
      return (
        <AddExtraProductModalForm
          open={isModalOpen}
          onClose={handleCloseModal}
          action={ActionTypes?.CREATE}
          formData=""
          searchParams={searchParams}
        />
      );

    case userPurchaseList:
      return (
        <AddUserPurchaseModalForm
          open={isModalOpen}
          onClose={handleCloseModal}
          action={ActionTypes?.CREATE}
          formData=""
        />
      );

    case services:
      return (
        <AddServiceModalForm
          open={isModalOpen}
          onClose={handleCloseModal}
          action={ActionTypes?.CREATE}
          formData=""
        />
      );

    case membershipProducts:
      return (
        <AddMembershipProductModalForm
          open={isModalOpen}
          onClose={handleCloseModal}
          action={ActionTypes?.CREATE}
          formData=""
        />
      );

    case userMemberships:
      return (
        <AddUserMembershipModalForm
          open={isModalOpen}
          onClose={handleCloseModal}
          action={ActionTypes?.CREATE}
          formData=""
        />
      );

    case tags:
      return (
        <AddTagModalForm
          open={isModalOpen}
          onClose={handleCloseModal}
          action={ActionTypes?.CREATE}
          formData=""
        />
      );
    case coupons:
      return (
        <AddCouponModalForm
          open={isModalOpen}
          onClose={handleCloseModal}
          action={ActionTypes?.CREATE}
          formData=""
        />
      );
    case sessions:
      return (
        <AddSessionModalForm
          open={isModalOpen}
          onClose={handleCloseModal}
          action={ActionTypes?.CREATE}
          formData={formData}
          isOverviewPage={isOverviewPage}
          refresh={refreshGrid}
          selectedClub={selectedClub}
        />
      );
    default:
      return null;
  }
};

export default renderPageForm;
