/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import { isEmpty, forEach, minBy, groupBy, map } from "lodash";
import {
  Modal,
  Box,
  Button,
  Divider,
  Paper,
  Slide,
  Grid,
  Typography,
  IconButton,
  FormControl,
  FormHelperText,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
} from "@mui/material";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { ClubsMultiSelect } from "app/shared/molecules";
import { FormTextField } from "app/shared/atoms";
import { AddExtraProductValidationSchema } from "app/shared/validations/ExtraValidationSchema";

import { Form, Formik, FieldArray, FormikProps } from "formik";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { FileUploadField } from "app/shared/atoms";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import { useAuth } from "../../../../AuthContext";
import { AppDispatch } from "../../../redux/store";
import { defaultGeneralInformationValue } from "../../../model/extra-model";
import {
  createExtraProduct,
  updateExtraProduct,
  fetchExtraProductsList,
} from "../extrasApiService";
import { AntSwitch } from "app/shared/components/BlackSwitch";
import ConfirmDialog from "app/shared/components/ConfirmDialog"; 
import { SubmitButton } from "app/shared/molecules";

interface ModalFormProps {
  open: boolean;
  onClose: () => void;
  type?: any;
  action?: any;
  formData?: any;
  page?: any;
  refreshClubsGrid?: any;
  refresh?: any;
  setRefresh?: any;
  callback?: any;
  searchParams?: any;
}

const brownButtonStyle = {
  backgroundColor: "#333333",
  color: "white", // Text color
};

const BoldTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold", // Set the font weight to bold
}));

const AddExtraProductModalForm: React.FC<ModalFormProps> = ({
  open,
  onClose,
  formData,
  callback,
  searchParams,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const formikRef = useRef<FormikProps<any>>(null);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const { user } = useAuth();
  const [editData, setEditData] = useState<any>();
  const [showSizeOption, setSizeOption] = useState<any>(true);

  // Set all the form data used for building the request object to the API finally
  useEffect(() => {
    if (formData?._id) {
      const formattedData = map(
        groupBy(formData?.variants, "club"),
        (group: any, clubKey: any) => ({
          club: clubKey,
          name: formData?.clubs?.find((y: any) => y?._id === clubKey)?.name,
          availableSizes: group?.map((x: any) => ({ ...x })),
        })
      );

      const checkShowOption =
        formData?.variants?.[0]?.size !== undefined &&
        formData?.variants?.[0]?.size !== "";

      setSizeOption(checkShowOption);

      setEditData({
        ...formData,
        clubs: formData?.clubs?.map((x: any) => x?._id),
        showSizeOption: checkShowOption,
        variants: formattedData,
        applyVat: formData?.taxPercentage > 0 ? true : false,
        // variants: checkShowOption
        //   ? formData?.variants?.map(
        //       (x: any) =>
        //         (x = {
        //           ...x,
        //           name: formData?.clubs?.find((y: any) => y?._id === x?.club)
        //             ?.name,
        //           availableSizes: x?.variants,
        //         })
        //     ) || []
        //   : formData?.variants,
      });
    }
  }, [formData]);

  /**
   * Handle Next on button click to load the next step if second page call the api
   *
   * @function
   * @returns void
   */
  const handleSubmit = async (values?: any, actions?: any) => {

    try {
      if (formData?._id) {
        delete values._id; 
          const response = await dispatch(updateExtraProduct(formData?._id, { ...values }));
  
        if (response) {
          if (typeof callback === "function") {
            callback(); 
          }
            onClose();
        }
      } else {
        const response = await dispatch(createExtraProduct({ ...values }));
        if (response) {
          onClose(); 
          dispatch(fetchExtraProductsList({ page: 1 })); 
        }
      }
    } catch (error) {
      console.error("Error during submission:", error);
    } finally {
      actions.setSubmitting(false); 
    }
  };

  /**
   * Handle close on button click
   *
   * @function
   * @returns void
   */
  const handleCloseModal = (event?: any, reason?: any) => {
    if (formikRef?.current?.dirty) {
      // Show confirmation dialog if there are unsaved changes
      setConfirmOpen(true);
    } else {
      onClose();
    }
  };

    // Handle close confirmation dialog
    const handleCancelClose = () => {
      setConfirmOpen(false);
    };
  
    // Handle confirm close action
    const handleConfirmClose = () => {
      setConfirmOpen(false);
      onClose();
    };
  const handleOnChange =
    (objectKey: string, setFieldValue?: any) => (e: any) => {
      const val = e?.target?.value;
      setFieldValue(`${objectKey}`, val);
    };

  const handleSwitchChange = (
    objectKey: string,
    checked: boolean,
    setFieldValue: any
  ) => {
    setFieldValue(`${objectKey}`, checked);
  };

  return (
    <><Modal
      open={open}
      onClose={handleCloseModal}
      closeAfterTransition
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: "rgba(0,0,0,0.5)",
          },
        },
      }}
    >
      <Slide in={open} direction="left">
        <Paper
          style={{
            position: "absolute",
            right: "0",
            transform: "translateY(-50%)",
            width: "35%",
            padding: "25px",
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Box>
            {/* Content of the sliding modal */}
            <BoldTypography variant="h6" style={{ marginBottom: "1rem" }}>
              {formData?._id ? t("editNewProduct") : t("addNewProduct")}
            </BoldTypography>
            <Divider />
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleCloseModal}
              aria-label="close"
              sx={{
                position: "absolute",
                top: "15px",
                right: "24px",
                cursor: "pointer",
              }}
            >
              <CloseIcon />
            </IconButton>
            <div>
              <Formik
              innerRef={formikRef}
                initialValues={
                  formData?._id
                    ? editData
                    : {
                        ...defaultGeneralInformationValue,
                      }
                }
                onSubmit={(values, actions) => {
                  let allVariants: any = [];
                  let totalQuantity = 0;

                  if (values?.variants) {
                    forEach(values.variants, (element: any) => {
                      if (element?.availableSizes?.length > 0) {
                        forEach(element?.availableSizes, (element1: any) => {
                          allVariants.push({
                            ...element1,
                            availableUnits: parseInt(element1?.availableUnits),
                            unitPrice: parseFloat(element1?.unitPrice),
                            club:
                              element?.club || element?._id || element1?.club,
                            showToUser: values?.showToUser,
                          });
                          totalQuantity += parseInt(element1.availableUnits);
                        });
                      } else {
                        allVariants.push({
                          ...element,
                          availableUnits: parseInt(element?.availableUnits),
                          unitPrice: parseFloat(element?.unitPrice),
                          showToUser: values?.showToUser,
                        });
                        totalQuantity += parseInt(element.availableUnits);
                      }
                    });
                  }

                  const productPriceToShow = minBy(
                    allVariants,
                    (item: any) => item?.unitPrice
                  )?.unitPrice;

                  let manipulateData: any = {
                    ...values,
                    totalQuantity,
                    productPriceToShow,
                    taxPercentage: parseFloat(values?.taxPercentage || 0),
                    ...(values?.description && {
                      description: values?.description,
                    }),
                  };

                  if (formData?._id) {
                    // Edit mode added new products in seperate key

                    let checkNewProduct = allVariants?.filter(
                      (x: any) => !x?.productCode
                    );
                    manipulateData = {
                      ...manipulateData,
                      variants: allVariants?.filter((x: any) => x?.productCode),
                      ...(checkNewProduct?.length > 0 && {
                        newvariants: checkNewProduct,
                      }),
                    };
                  } else {
                    manipulateData = {
                      ...manipulateData,
                      variants: allVariants,
                    };
                  }

                  if (
                    manipulateData?.newvariants?.length > 0 ||
                    manipulateData?.variants?.length > 0
                  ) {
                    if (manipulateData?.variants?.length === 0) {
                      delete manipulateData.variants;
                    }
                    if (manipulateData?.description === "") {
                      delete manipulateData.description;
                    }

                    handleSubmit(
                      {
                        ...manipulateData,
                        createdBy: user?.userId,
                      },
                      actions
                    );
                  } else {
                  }
                }}
                enableReinitialize
                validationSchema={AddExtraProductValidationSchema(
                  showSizeOption
                )}
              >
                {({ values, errors, setFieldValue, touched, isSubmitting }: any) => {
                  return (
                    <>
                      <Form>
                        <Grid
                          container
                          spacing={2}
                          style={{ marginTop: "10px" }}
                        >
                          <Grid item xs={12}>
                            <FormTextField
                              name="name"
                              label={t("productName")}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <ClubsMultiSelect
                              label="Club"
                              multiple={true}
                              value={values?.clubs ? values?.clubs : []} // Pass the value from Formik's state
                              onChange={(clubs: any, newValue: any) => {
                                const newData = newValue?.map((x: any) => {
                                  const checkAlreadyExists =
                                    values?.variants?.find(
                                      (y: any) => y?.club === x?._id
                                    );
                                  if (checkAlreadyExists) {
                                    // Preserve existing data and just update the club
                                    return {
                                      ...checkAlreadyExists,
                                      club: x?._id,
                                    };
                                  } else {
                                    // Add new data with availableSizes array if showSizeOption is true
                                    //if (values?.showSizeOption) {
                                    return {
                                      club: x?._id,
                                      name: x?.name,
                                      expandAccordion: true,
                                      availableSizes: [
                                        {
                                          size: "",
                                          unitPrice: "",
                                          availableUnits: "",
                                        },
                                      ],
                                    };
                                    //}
                                    // else {
                                    //   // Otherwise, add new data without availableSizes
                                    //   return {
                                    //     club: x?._id,
                                    //     name: x?.name,
                                    //     unitPrice: x?.unitPrice || "",
                                    //     availableUnits: x?.availableUnits || "",
                                    //   };
                                    // }
                                  }
                                });

                                setFieldValue("variants", newData);

                                // Update the selected clubs
                                setFieldValue("clubs", clubs);
                              }}
                              error={touched?.clubs & errors?.clubs}
                            />
                            <FormHelperText>
                              {touched?.clubs && errors?.clubs ? (
                                <span
                                  className="MuiFormHelperText-root Mui-error"
                                  style={{ color: "#d32f2f" }}
                                >
                                  {errors?.clubs}
                                </span>
                              ) : (
                                ""
                              )}
                            </FormHelperText>
                          </Grid>

                          {/* <Grid item xs={12} sx={{ marginTop: "1rem" }}>
                            <Grid
                              container
                              spacing={2}
                              justifyContent={"space-between"}
                            >
                              <Grid
                                item
                                xs={6}
                                className="label uppercase"
                                sx={{ fontSize: "15px" }}
                              >
                                {t("applyVat")}
                              </Grid>
                              <Grid item xs={6}>
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                  sx={{ float: "right" }}
                                >
                                  <Typography sx={{ fontSize: "12px" }}>
                                    {t("No")}
                                  </Typography>
                                  <AntSwitch
                                    checked={values?.applyVat ? true : false}
                                    onChange={(e) => {
                                      handleSwitchChange(
                                        "applyVat",
                                        e.target.checked,
                                        setFieldValue
                                      );
                                    }}
                                  />
                                  <Typography sx={{ fontSize: "12px" }}>
                                    {t("Yes")}
                                  </Typography>
                                </Stack>
                              </Grid>
                            </Grid>
                          </Grid> */}

                          <Grid item xs={12}>
                            <Grid container spacing={2}>
                              <Grid
                                item
                                xs={6}
                                className="label uppercase"
                                sx={{ fontSize: "15px" }}
                              >
                                {t("VisibletoEnduser")}
                              </Grid>
                              <Grid item xs={6}>
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                  sx={{ float: "right" }}
                                >
                                  <Typography sx={{ fontSize: "12px" }}>
                                    {t("No")}
                                  </Typography>
                                  <AntSwitch
                                    checked={values?.showToUser ? true : false}
                                    onChange={(e) => {
                                      handleSwitchChange(
                                        "showToUser",
                                        e.target.checked,
                                        setFieldValue
                                      );
                                    }}
                                  />
                                  <Typography sx={{ fontSize: "12px" }}>
                                    {t("Yes")}
                                  </Typography>
                                </Stack>
                              </Grid>
                            </Grid>
                          </Grid>

                          {!formData?._id && (
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid
                                  item
                                  xs={6}
                                  className="label uppercase"
                                  sx={{ fontSize: "15px" }}
                                >
                                  {t("DoesTheProductHaveSizes")}
                                </Grid>
                                <Grid item xs={6}>
                                  <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                    sx={{ float: "right" }}
                                  >
                                    <Typography sx={{ fontSize: "12px" }}>
                                      {t("No")}
                                    </Typography>
                                    <AntSwitch
                                      checked={
                                        values?.showSizeOption ? true : false
                                      }
                                      onChange={(e) => {
                                        handleSwitchChange(
                                          `showSizeOption`,
                                          e.target.checked,
                                          setFieldValue
                                        );

                                        setSizeOption(e.target.checked);

                                        if (!values?.showSizeOption) {
                                          let newData = values?.variants?.map(
                                            (x: any) =>
                                              x?.availableSizes?.length > 0
                                                ? x
                                                : {
                                                    ...x,
                                                    availableSizes: [
                                                      {
                                                        size: "",
                                                        unitPrice: "",
                                                        availableUnits: "",
                                                      },
                                                    ],
                                                  }
                                          );
                                          setFieldValue("variants", newData);
                                        } else {
                                          let newData = values?.variants?.map(
                                            (x: any) =>
                                              (x = {
                                                ...x,
                                                unitPrice: "",
                                                availableUnits: "",
                                              })
                                          );
                                          setFieldValue("variants", newData);
                                        }
                                      }}
                                    />
                                    <Typography sx={{ fontSize: "12px" }}>
                                      {t("Yes")}
                                    </Typography>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}

                          <Grid item xs={12} sx={{ marginTop: "1rem" }}>
                            <FieldArray
                              name="variants"
                              render={(arrayHelpers: any) => (
                                <div>
                                  {values?.variants?.map(
                                    (x: any, index: number) => {
                                      return (
                                        <div
                                          key={`variant-${index}`}
                                          style={{
                                            marginBottom: "1rem",
                                            border: "1px solid #d0d4d7",
                                          }}
                                        >
                                          <Accordion
                                            key={`variant1-${index}`}
                                            elevation={index}
                                            defaultExpanded={true}
                                          >
                                            <AccordionSummary>
                                              <Grid container spacing={2}>
                                                <Grid item xs={11}>
                                                  <Typography
                                                    sx={{
                                                      fontWeight: 600,
                                                      color:
                                                        touched?.variants &&
                                                        !isEmpty(
                                                          errors?.variants?.[
                                                            index
                                                          ]
                                                        )
                                                          ? "#FF0000!important"
                                                          : "unset",
                                                    }}
                                                  >{`${x?.name}`}</Typography>
                                                </Grid>

                                                <Grid
                                                  item
                                                  xs={1}
                                                  style={{
                                                    justifyContent: "flex-end",
                                                  }}
                                                ></Grid>
                                              </Grid>
                                            </AccordionSummary>

                                            <AccordionDetails>
                                              <Grid container spacing={2}>
                                                {values ? (
                                                  <Grid item xs={12}>
                                                    <FieldArray
                                                      name={`variants.${index}.availableSizes`}
                                                      render={(
                                                        sizeArrayHelpers: any
                                                      ) => (
                                                        <div>
                                                          {x?.availableSizes?.map(
                                                            (
                                                              y: any,
                                                              sizeIndex: number
                                                            ) => {
                                                              return (
                                                                <div
                                                                  key={`side-${sizeIndex}`}
                                                                >
                                                                  <Grid
                                                                    container
                                                                    spacing={2}
                                                                    style={{
                                                                      marginLeft:
                                                                        "0px",
                                                                      marginBottom:
                                                                        "1rem",
                                                                    }}
                                                                  >
                                                                    {values?.showSizeOption && (
                                                                      <Grid
                                                                        item
                                                                        xs={4}
                                                                      >
                                                                        <FormTextField
                                                                          name={`variants.${index}.availableSizes.${sizeIndex}.size`}
                                                                          label={t(
                                                                            "availableSizes(OP)"
                                                                          )}
                                                                          value={
                                                                            values
                                                                              ?.variants?.[
                                                                              index
                                                                            ]
                                                                              ?.availableSizes?.[
                                                                              sizeIndex
                                                                            ]
                                                                              ?.size
                                                                          }
                                                                        />
                                                                      </Grid>
                                                                    )}
                                                                    <Grid
                                                                      item
                                                                      xs={3}
                                                                    >
                                                                      <FormTextField
                                                                        name={`variants.${index}.availableSizes.${sizeIndex}.availableUnits`}
                                                                        label={t(
                                                                          "QTY"
                                                                        )}
                                                                        value={
                                                                          values
                                                                            ?.variants?.[
                                                                            index
                                                                          ]
                                                                            ?.availableSizes?.[
                                                                            sizeIndex
                                                                          ]
                                                                            ?.availableUnits
                                                                        }
                                                                      />
                                                                    </Grid>
                                                                    <Grid
                                                                      item
                                                                      xs={3}
                                                                    >
                                                                      <FormTextField
                                                                        name={`variants.${index}.availableSizes.${sizeIndex}.unitPrice`}
                                                                        label={t(
                                                                          "sizePrice"
                                                                        )}
                                                                        value={
                                                                          values
                                                                            ?.variants?.[
                                                                            index
                                                                          ]
                                                                            ?.availableSizes?.[
                                                                            sizeIndex
                                                                          ]
                                                                            ?.unitPrice
                                                                        }
                                                                        showPriceSymbol={
                                                                          true
                                                                        }
                                                                      />
                                                                    </Grid>
                                                                    {values?.showSizeOption && (
                                                                      <Grid
                                                                        item
                                                                        xs={2}
                                                                        style={{
                                                                          position:
                                                                            "relative",
                                                                        }}
                                                                      >
                                                                        <IconButton
                                                                          edge="end"
                                                                          color="inherit"
                                                                          onClick={() =>
                                                                            sizeArrayHelpers.remove(
                                                                              sizeIndex
                                                                            )
                                                                          }
                                                                          aria-label="close"
                                                                          sx={{
                                                                            position:
                                                                              "absolute",
                                                                            top: "55px",
                                                                            right:
                                                                              "24px",
                                                                            cursor:
                                                                              "pointer",
                                                                          }}
                                                                        >
                                                                          <CloseIcon />
                                                                        </IconButton>
                                                                      </Grid>
                                                                    )}
                                                                  </Grid>

                                                                  {values
                                                                    ?.variants?.[
                                                                    index
                                                                  ]
                                                                    ?.availableSizes
                                                                    ?.length ===
                                                                    sizeIndex +
                                                                      1 &&
                                                                    values?.showSizeOption && (
                                                                      <>
                                                                        <Divider
                                                                          style={{
                                                                            marginTop:
                                                                              "1rem",
                                                                          }}
                                                                        >
                                                                          {" "}
                                                                          <AddCircleOutlineIcon
                                                                            onClick={() => {
                                                                              sizeArrayHelpers.push(
                                                                                {
                                                                                  size: "",
                                                                                  unitPrice:
                                                                                    "",
                                                                                  availableUnits:
                                                                                    "",
                                                                                  club: x?.club,
                                                                                }
                                                                              );
                                                                            }}
                                                                            style={{
                                                                              cursor:
                                                                                "pointer",
                                                                            }}
                                                                          />
                                                                        </Divider>
                                                                      </>
                                                                    )}
                                                                </div>
                                                              );
                                                            }
                                                          )}

                                                          {x?.availableSizes
                                                            ?.length === 0 && (
                                                            <>
                                                              {" "}
                                                              <Divider
                                                                style={{
                                                                  marginTop:
                                                                    "1rem",
                                                                }}
                                                              >
                                                                {" "}
                                                                <AddCircleOutlineIcon
                                                                  onClick={() => {
                                                                    sizeArrayHelpers.push(
                                                                      {
                                                                        availableSizes:
                                                                          [
                                                                            {
                                                                              size: "",
                                                                              unitPrice:
                                                                                "",
                                                                              availableUnits:
                                                                                "",
                                                                            },
                                                                          ],
                                                                      }
                                                                    );
                                                                  }}
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                />
                                                              </Divider>
                                                            </>
                                                          )}
                                                        </div>
                                                      )}
                                                    ></FieldArray>
                                                  </Grid>
                                                ) : null}
                                              </Grid>
                                            </AccordionDetails>
                                          </Accordion>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              )}
                            ></FieldArray>
                          </Grid>
                        </Grid>

                        {/* {values?.applyVat && (
                          <Grid
                            item
                            xs={12}
                            sx={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}
                          >
                            <FormControl fullWidth>
                              <FormTextField
                                name="taxPercentage"
                                label={t("TaxPercentage")}
                                onFocus={() => {
                                  if (values?.taxPercentage === 0) {
                                    setFieldValue("taxPercentage", "");
                                  }
                                }}
                                onBlur={() => {
                                  if (
                                    values?.taxPercentage === null ||
                                    values?.taxPercentage === ""
                                  ) {
                                    setFieldValue("taxPercentage", 0);
                                  }
                                }}
                              />
                            </FormControl>
                          </Grid>
                        )} */}

                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <Typography
                              className="uppercase label"
                              sx={{ marginBottom: "1rem", fontSize: "12px" }}
                            >
                              {t("description")} {t("optional")}
                            </Typography>
                            <TextareaAutosize
                              value={values?.description}
                              onChange={handleOnChange(
                                "description",
                                setFieldValue
                              )}
                              minRows={5}
                              style={{ marginTop: "15px" }}
                            />
                            <FormHelperText>
                              {touched?.productDetails &&
                              errors?.productDetails ? (
                                <span
                                  className="MuiFormHelperText-root Mui-error"
                                  style={{ color: "#d32f2f" }}
                                >
                                  {errors?.productDetails}
                                </span>
                              ) : (
                                ""
                              )}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{ marginTop: "20px" }}>
                          <Typography
                            variant="body2"
                            className="uppercase label"
                            gutterBottom
                            sx={{ marginBottom: "1rem" }}
                          >
                            {t("Image")} {t("optional")}
                          </Typography>

                          <FileUploadField
                            name="attachments"
                            id="attachments"
                            accept="image/*"
                            multiple
                            images={values?.attachments}
                            setFieldValue={setFieldValue}
                            errors={errors}
                          />
                        </Grid>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Grid
                            container
                            spacing={2}
                            style={{ marginTop: "15px" }}
                          >
                            <Grid
                              item
                              xs={12}
                              container
                              justifyContent="flex-end"
                            >
                              <SubmitButton
                                disabled={isSubmitting}
                                type="submit"
                              >
                                {formData?._id
                                  ? t("saveChanges")
                                  : t("saveNewProduct")}
                              </SubmitButton>
                            </Grid>
                          </Grid>
                        </div>
                      </Form>
                    </>
                  );
                }}
              </Formik>
            </div>
          </Box>
        </Paper>
      </Slide>
    </Modal>
    {/* Confirmation dialog for unsaved changes */}
    <ConfirmDialog
        open={confirmOpen}
        onClose={handleCancelClose}
        onConfirm={handleConfirmClose}
      />
    
    </>
  );
};

export default AddExtraProductModalForm;
