import React, { Suspense, useRef, useState } from "react";
import { Modal, Paper, Slide } from "@mui/material";
import { FormikProps } from "formik";

import EditBookingModalForm from "./EditBookingModalForm";
import ConfirmDialog from "app/shared/components/ConfirmDialog"; 
import { ActionTypes } from "../../../config/constants";

const AddBookingInformationTab = React.lazy(
  () => import("./Tabs/AddBookingInformationTab")
);
interface ModalFormProps {
  open: boolean;
  onClose: () => void;
  action?: any;
  formData?: any;
  refreshBookingGrid?: any;
  isOverviewPage?: boolean;
}

const AddNewBookingModalForm: React.FC<ModalFormProps> = ({
  open,
  onClose,
  action,
  formData,
  refreshBookingGrid,
  isOverviewPage = false,
}) => {
  const formikRef = useRef<FormikProps<any>>(null);
  const [confirmOpen, setConfirmOpen] = useState(false);

      /**
   * Handle close on button click
   *
   * @function
   * @returns void
   */
      const handleCloseModal = (event?: any, reason?: any) => {
        if (formikRef?.current?.dirty) {
          // Show confirmation dialog if there are unsaved changes
          setConfirmOpen(true);
        } else {
          onClose();
        }
      };
  
      // Handle close confirmation dialog
      const handleCancelClose = () => {
        setConfirmOpen(false);
      };
    
      // Handle confirm close action
      const handleConfirmClose = () => {
        setConfirmOpen(false);
        onClose();
      };


  return (
    <><Modal open={open} onClose={handleCloseModal} closeAfterTransition>
      <Slide in={open} direction="left">
        <Paper
          style={{
            position: "absolute",
            right: "0",
            transform: "translateY(-50%)",
            width: "35%",
            padding: "20px",
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Suspense fallback={<div>Loading...</div>}>
            {action === ActionTypes?.CREATE ? (
              <AddBookingInformationTab
                open={open}
                onClose={onClose}
                formData={formData}
                refreshBookingGrid={refreshBookingGrid}
                action={ActionTypes?.CREATE}
                isOverviewPage={isOverviewPage}
                formikRef={formikRef}
                handleCloseModal={handleCloseModal}
              />
            ) : (
              <EditBookingModalForm
                open={open}
                onClose={onClose}
                formData={formData}
                refreshBookingGrid={refreshBookingGrid}
                action={ActionTypes?.EDIT}
              />
            )}{" "}
          </Suspense>
        </Paper>
      </Slide>
    </Modal>
    
    {/* Confirmation dialog for unsaved changes */}
    <ConfirmDialog
    open={confirmOpen}
    onClose={handleCancelClose}
    onConfirm={handleConfirmClose}
  />
    </>
  );
};

export default AddNewBookingModalForm;
