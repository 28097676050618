import moment from "moment";

// All the pages should be use this function and @prabhu if any date oriented functions move this file
export function formatDate(date: any, dateformat = "DD/MM/YYYY", isUTC = true) {
  return isUTC
    ? moment?.utc(date).format(dateformat)
    : moment(date).format(dateformat);
}

export function formatTime(date: any, timeformat = "HH:mm A", isUTC = false) {
  if (isUTC) return moment.utc(date).format(timeformat);
  return moment(date).format(timeformat);
}

export function longDate(inputDate: any, isUTC = false) {
  let date: any = new Date(inputDate);
  if (isUTC) date = moment.utc(date);
  //const options: any = { weekday: "long", month: "long", day: "numeric" };
  //const formattedDate = date.toLocaleDateString("en-US", options);
  return moment(date).format("dddd, MMMM DD");
  //return formattedDate;
}

export function startEndDate(inputDateTime: any, field = "startDate") {
  const originalMoment = moment(inputDateTime);
  let newDateTime: any;

  if (field === "endDate") {
    // Set the time part to 00:00:00 and format it as ISO string
    newDateTime = originalMoment.endOf("day").toISOString();
    return newDateTime;
  }
  // Set the time part to 00:00:00 and format it as ISO string
  newDateTime = originalMoment.startOf("day").toISOString();
  return newDateTime;
}

export const getWeekNumbersForMonth = (date: any) => {
  // Parse the given date using moment
  const mDate = moment(date);

  // Get the first and last days of the month
  const firstDayOfMonth = mDate.clone().startOf("month");
  const lastDayOfMonth = mDate.clone().endOf("month");

  // Get week numbers
  const weekNumbers = new Set();
  let currentDay = firstDayOfMonth;

  while (currentDay <= lastDayOfMonth) {
    weekNumbers.add(currentDay.isoWeek());
    currentDay = currentDay.add(1, "days");
  }

  return Array.from(weekNumbers);
};

export const getWeekNumber = (date: any) => {
  const weekNumber = moment(date).isoWeek();
  return weekNumber;
};

export const getStartAndEndOfWeek = (
  weekNumber: number,
  year = parseInt(moment().format("YYYY"))
) => {
  // Get the start of the week (ISO weeks start on Monday)
  const startOfWeek = moment()
    .year(year)
    .isoWeek(weekNumber)
    .startOf("isoWeek");
  // Get the end of the week
  const endOfWeek = moment().year(year).isoWeek(weekNumber).endOf("isoWeek");

  return {
    startOfWeek: startOfWeek.format("YYYY-MM-DD"),
    endOfWeek: endOfWeek.format("YYYY-MM-DD"),
  };
};
export function getIntermediateMonths(startDate: any, endDate: any) {
  const intermediateDates = [];
  let currentDate = moment(startDate).add(1, "day"); // Initialize current date with start date

  // Loop until current date is less than or equal to end date
  while (currentDate.isSameOrBefore(endDate, "month")) {
    intermediateDates.push(currentDate.format("MMM")); // Add current date to intermediate dates array
    currentDate.add(1, "month"); // Increment current date by one day
  }

  return intermediateDates;
}

export function getStartAndEndOfTime(slots: any[], field: 'startTime' | 'endTime' = 'startTime') {
  if (slots?.length > 0) {
    if (field === 'startTime') {
      return slots[0];  // First slot for startTime
    } else {
      return moment(slots[slots.length - 1], 'HH:mm') // Last slot for endTime
        .add(30, 'minutes')
        .format('HH:mm');
    }
  }
  return null; // Return null if no slots are provided
}
