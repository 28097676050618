// Images from the Contents -> Assets Folder
import DashboardMenuImage from "../../content/assets/dashboard.svg";
import UsersMenuImage from "../../content/assets/person.svg";
import ClubsMenuImage from "../../content/assets/sports_tennis.svg";
import BookingsMenuImage from "../../content/assets/today.svg";
import CoachingMenuImage from "../../content/assets/sports.svg";
import CoachingScheduleMenuImage from "../../content/assets/calendar_month.svg";
import CoachingProductsMenuImage from "../../content/assets/person_celebrate.svg";
import CoachingProductsWhiteImage from "../../content/assets/person_celebrate.svg";
import SettingsMenuImage from "../../content/assets/settings.svg";
import CoachingSessionsMenuImage from "../../content/assets/settings.svg";
import FeedMenuImage from "../../content/assets/art_track.svg";
import TournamentsMenuImage from "../../content/assets/trophy.svg";
import SubscriptionsMenuImage from "../../content/assets/recent_actors.svg";
import CouponsMenuImage from "../../content/assets/loyalty.svg";
import TagsMenuImage from "../../content/assets/tags.svg";
import ApparelMenuImage from "../../content/assets/add_business.svg";
import LogOutMenuImage from "../../content/assets/move_item.svg";
import MembershipMenuImage from "../../content/assets/memberships.svg";
import DashboardMenuWhiteImage from "../../content/assets/dashboard_white.png";
import UsersMenuWhiteImage from "../../content/assets/person_white.png";
import ClubsMenuWhiteImage from "../../content/assets/sports_tennis_white.png";
import BookingsMenuWhiteImage from "../../content/assets/today_white.png";
import CoachingMenuWhiteImage from "../../content/assets/sports_white.png";
import FeedMenuWhiteImage from "../../content/assets/art_track_white.svg";
import TournamentsMenuWhiteImage from "../../content/assets/trophy_white.png";
import SubscriptionsMenuWhiteImage from "../../content/assets/recent_actors_white.png";
import CouponsMenuWhiteImage from "../../content/assets/loyalty_white.png";
import ApparelMenuWhiteImage from "../../content/assets/add_business_white.svg";
import MembershipMenuWhiteImage from "../../content/assets/memberships_white.svg";
import SettingsMenuWhiteImage from "../../content/assets/settings_white.svg";
import TagsMenuWhiteImage from "../../content/assets/tags-white.svg";
import LoginCoverImage from "../../content/assets/Login_Cover_Image.png";
import CoachLoginCoverImage from "../../content/assets/Coach_Login_Cover_Image.png";
import ForgotPasswordCoverImage from "../../content/assets/Forgot_Password_Cover_Image.png";
import NewPasswordCoverImage from "../../content/assets/New_Password_Cover_Image.png";
import SuccessPasswordChangeCoverImage from "../../content/assets/Success_Password_Change_Cover_Image.png";
import LogoCasaPadel from "../../content/assets/Logo_Resa_Padel.png";
import LogoCoachCasaPadel from "../../content/assets/Logo_Coach_Resa_Padel.png";
import PersonPlayingPadel from "../../content/assets/person_tennis.png";
import Trophy from "../../content/assets/emoji_events.png";
import CourtSideOne from "../../content/assets/court_side_1.png";
import CourtSideTwo from "../../content/assets/court_side_2.png";
import CourtSideThree from "../../content/assets/court_side_3.png";
import CourtSideFour from "../../content/assets/court_side_4.png";
import FilterByTag from "../../content/assets/page_info.png";
import ImageUpload from "../../content/assets/add_photo_alternate.png";
import EmojiIcon from "../../content/assets/smile.png";
import Deactivate from "../../content/assets/deactivate.gif";
import SingleLeft from "../../content/assets/single_left.png";
import SingleRight from "../../content/assets/single_right.png";
import Court from "../../content/assets/court.png";
import GroupsImage from "../../content/assets/groups.svg";
import DollarImage from "../../content/assets/dollar.svg";
import DollarGreenImage from "../../content/assets/dollar-green.svg";
import Visibility from "../../content/assets/visibility.svg";
import VisibilityOff from "../../content/assets/visibility_off.svg";
import NotificationsUnread from "../../content/assets/notifications_unread.svg";
import FiberManualRecord from "../../content/assets/fiber_manual_record.svg";
import RedAlert from "../../content/assets/Red_Alert.svg";
import VerifyLicense from "../../content/assets/Verify_License.svg";
import Reschedule from "../../content/assets/reschedule.gif";
import SuccessPassword from "../../content/assets/success_password.gif";
import BackButtonImage from "../../content/assets/arrow_left_alt.svg";
import AlertCircle from "../../content/assets/alert-circle.png";
import ProductPlaceholder from "../../content/assets/product-placeholder.png";
import RefundImage from "../../content/assets/refund.gif";
import RefundBank from "../../content/assets/account_balance.png";
import RefundCash from "../../content/assets/payments.png";
import BroadcastMessageIcon from "../../content/assets/broadcast_message.png";
import BroadcastMessageGifIcon from "../../content/assets/broadcast_message.gif";
import CancelMultipleBookings from "../../content/assets/cancel_multiple_bookings.gif";
import PersonDefaultImage from "../../content/assets/person.png";
import ProfileAvatar from "../../content/assets/profile_avatar.svg";
import Block from "../../content/assets/block.png";
import BlockDark from "../../content/assets/block-dark.png";
import BookCourtNew from "../../content/assets/Book_Court.png";
import BookCoachingSession from "../../content/assets/Book_Coaching_Session.png";
import BookTournament from "../../content/assets/Book_Tournament.png";
import infoIcon from "../../content/assets/info-icon.jpg";

// Static page images
import PlayStore from "../../content/assets/static-page-images/play-store.png";
import AppleStore from "../../content/assets/static-page-images/apple-store.png";
import Search from "../../content/assets/static-page-images/search.png";
import BookCourt from "../../content/assets/static-page-images/book-court.png";
import Smartphone from "../../content/assets/static-page-images/smartphone.png";
import FileText from "../../content/assets/static-page-images/file-text.png";
import Phone_1_1_Image from "../../content/assets/static-page-images/phone-1_1.png";
import Phone_1_2_Image from "../../content/assets/static-page-images/phone-1_2.png";
import Phone_2_1_Image from "../../content/assets/static-page-images/phone-2_1.png";
import Phone_2_2_Image from "../../content/assets/static-page-images/phone-2_2.png";
import Phone_2_3_Image from "../../content/assets/static-page-images/phone-2_3.png";
import Phone_3_1_Image from "../../content/assets/static-page-images/phone-3_1.png";
import Phone_3_2_Image from "../../content/assets/static-page-images/phone-3_2.png";
import Phone_4_1_Image from "../../content/assets/static-page-images/phone-4_1.png";
import Phone_4_2_Image from "../../content/assets/static-page-images/phone-4_2.png";
import Phone_4_3_Image from "../../content/assets/static-page-images/phone-4_3.png";
import Phone_4_4_Image from "../../content/assets/static-page-images/phone-4_4.png";
import TwitterIcon from "../../content/assets/static-page-images/twitter.png";
import InstagramIcon from "../../content/assets/static-page-images/instagram.png";
import HeroImage from "../../content/assets/static-page-images/hero.png";
import BottomImage from "../../content/assets/static-page-images/bottom.png";

import LinkedinIcon from "../../content/assets/static-page-images/linkedin.png";

export {
  DashboardMenuImage,
  UsersMenuImage,
  ClubsMenuImage,
  BookingsMenuImage,
  CoachingMenuImage,
  CoachingScheduleMenuImage,
  CoachingProductsMenuImage,
  CoachingSessionsMenuImage,
  TagsMenuImage,
  FeedMenuImage,
  TournamentsMenuImage,
  SubscriptionsMenuImage,
  CouponsMenuImage,
  ApparelMenuImage,
  MembershipMenuImage,
  LogOutMenuImage,
  DashboardMenuWhiteImage,
  UsersMenuWhiteImage,
  ClubsMenuWhiteImage,
  BookingsMenuWhiteImage,
  CoachingMenuWhiteImage,
  FeedMenuWhiteImage,
  TournamentsMenuWhiteImage,
  SubscriptionsMenuWhiteImage,
  TagsMenuWhiteImage,
  CouponsMenuWhiteImage,
  ApparelMenuWhiteImage,
  MembershipMenuWhiteImage,
  SettingsMenuImage,
  SettingsMenuWhiteImage,
  LoginCoverImage,
  CoachLoginCoverImage,
  ForgotPasswordCoverImage,
  NewPasswordCoverImage,
  SuccessPasswordChangeCoverImage,
  LogoCasaPadel,
  LogoCoachCasaPadel,
  PersonPlayingPadel,
  Trophy,
  CourtSideOne,
  CourtSideTwo,
  CourtSideThree,
  CourtSideFour,
  FilterByTag,
  ImageUpload,
  EmojiIcon,
  Deactivate,
  SingleLeft,
  SingleRight,
  Court,
  GroupsImage,
  DollarImage,
  DollarGreenImage,
  Visibility,
  VisibilityOff,
  CoachingProductsWhiteImage,
  NotificationsUnread,
  FiberManualRecord,
  RedAlert,
  VerifyLicense,
  Reschedule,
  SuccessPassword,
  BackButtonImage,
  AlertCircle,
  ProductPlaceholder,
  RefundImage,
  RefundBank,
  RefundCash,
  BroadcastMessageIcon,
  CancelMultipleBookings,
  BroadcastMessageGifIcon,
  PersonDefaultImage,
  PlayStore,
  AppleStore,
  Search,
  FileText,
  Smartphone,
  BookCourt,
  Phone_1_1_Image,
  Phone_1_2_Image,
  Phone_2_1_Image,
  Phone_2_2_Image,
  Phone_2_3_Image,
  Phone_3_1_Image,
  Phone_3_2_Image,
  Phone_4_1_Image,
  Phone_4_2_Image,
  Phone_4_3_Image,
  Phone_4_4_Image,
  TwitterIcon,
  InstagramIcon,
  LinkedinIcon,
  HeroImage,
  BottomImage,
  ProfileAvatar,
  Block,
  BlockDark,
  BookCourtNew,
  BookCoachingSession,
  BookTournament,
  infoIcon,
};

const config = {
  VERSION: process.env.VERSION,
};

export default config;
export const APP_ENV = process.env.REACT_APP_APP_ENV ?? "development";
export const SERVER_API_URL = process.env.REACT_APP_SERVER_API_URL;
export const CDN_URL = process.env.REACT_APP_CDN_URL;
export const ORIGINAL_MEDIA_BUCKET_URL =
  process.env.REACT_APP_ORIGINAL_MEDIA_BUCKET_URL;
export const API_KEY = process.env.REACT_APP_API_KEY;
export const IMAGE_BASE_PATH_BUCKET_URL =
  process.env.REACT_APP_COMMON_ASSETS_URL ??
  "https://casapadel-common.s3.eu-west-1.amazonaws.com/";
export const PLACE_ID = "";
export const STATUS_UPDATE_IMAGE =
  "https://s3-alpha-sig.figma.com/img/985c/2c20/359643988c88078740c49da0a7608395?Expires=1701043200&Signature=nhqGXQASMgdpMqV4D66IUj5usW0H6p3a1I4EHNeJxGoy57igFDuS7G3zLCTF4x8fhUbNhv5EsLaGiNIjU8QusKzsnt~pVa742MgbMT1FwA6ow0M0Ot6oSo89XiKdAbUKqMLExs2QT3Mx4PlfSkm7tv7e-D6i17tTjo5N9b2FYS53-F3Itul58G6iyuYKuEd3z7rMbSOyk0Jg4aRCY7JSKpvD8NPf-BJkdq0dI4YiHrfRoxyyEIhQ0WIxq65UQW-vQDxTjDYCIV4khk585DDdAkkKDFBMaDAc4d8BWdOevaBic5DaCHxHmPn-YI1bBgic2c1Qsk58WEBh9B8wgJh-0Q__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4";
export const USER_MANAGEMENT_URL = `${SERVER_API_URL}/users`;
export const DASHBOARD_URL = `${SERVER_API_URL}/dashboard`;

export const TIMEOUT = 1 * 60 * 1000;

export const DEFAULT_PAGESIZE = 10;

export const VALIDATION = {
  ALPHABETS_SPACE: /^[a-zA-Z ]*$/,
  PASSWORD_REGEX:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/,
  NUMBER_WITH_DECIMAL: /^\d*\.?\d{0,2}$/,
};

export const USER_ROLE = {
  COACH: "coach",
  CUSTOMER: "customer",
};

export const AUTHORITIES = {
  CLUB_ADMIN: "CLUB_ADMIN",
  MASTER_ADMIN: "MASTER_ADMIN",
  club_admin: "club_admin",
  super_admin: "super_admin",
};
export const messages = {
  DATA_ERROR_ALERT: "Internal Error",
};
export const APP_AUTH_TOKEN_KEY = "AUTH_TOKEN";
export const APP_DATE_FORMAT = "DD/MM/YY HH:mm";
export const APP_TIMESTAMP_FORMAT = "DD/MM/YY HH:mm:ss";
export const APP_LOCAL_DATE_FORMAT = "DD/MM/YYYY";
export const APP_LOCAL_DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const APP_LOCAL_DATETIME_FORMAT_CUSTOM = "YYYY-MM-DD HH:mm";
export const APP_LOCAL_DATETIME_MS_FORMAT = "YYYY-MM-DD HH:mm:ss.SSS";
export const APP_LOCAL_DATETIME_FORMAT_Z = "YYYY-MM-DDTHH:mm Z";
export const APP_WHOLE_NUMBER_FORMAT = "0,0";
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = "0,0.[00]";
export const LOGIN_PAGE_TITLE = "Login";
export const FORGOT_PASSWORD_PAGE_TITLE = "Forgot Password";
export const CHANGE_PASSWORD_PAGE_TITLE = "Change Password";
export const NEW_PASSWORD_PAGE_TITLE = "New Password";
export const VERIFY_OTP_PAGE_TITLE = "Verify OTP";
export const SUCCESS_PASSWORD_CHANGE_PAGE_TITLE = "Success Password Change";
export const LOGIN_PAGE_SUB_TITLE =
  "Please log in and let's pick up where you left off";
export const FORGOT_PASSWORD_PAGE_SUB_TITLE =
  "No problem! Please enter your email to start the recovering process";
export const VERIFY_OTP_PAGE_SUB_TITLE =
  "Please enter your OTP to access create new password";
export const NEW_PASSWORD_PAGE_SUB_TITLE =
  "Please enter your new password to gain access to the portal";
export const SUCCESS_PASSWORD_CHANGE_PAGE_SUB_TITLE =
  "Your new password has been successfully created";
export const FORGOT_PASSWORD = "Forgot Password?";
export const DIDNT_RECEIVED_OTP_TEXT = "Didn't received it?";

export const INTCONSTANTS = {
  INT_MAX: 2147483647,
};

// List of allowed file extensions while uploading
export const ALLOWED_UPLOAD_FILE_EXTENSIONS = [
  ".csv",
  ".out",
  ".txt",
  ".xls",
  ".xlsx",
  ".pdf",
  ".jpg",
  ".png",
];

export const CHAR_LIMIT = {
  LIMIT_150: 150,
};

export enum UserType {
  CUSTOMER = "customer",
  COACH = "coach",
  SUPERADMIN = "super_admin",
  CLUBADMIN = "club_admin",
}
export enum Status {
  ACTIVE = "active",
  INACTIVE = "inactive",
  PENDING = "pending",
}
export enum Gender {
  MALE = "male",
  FEMALE = "female",
  OTHERS = "others",
  ALL = "all",
  MIXED = "mixed",
}
export enum SportsType {
  PADEL = "padel",
  TENNIS = "tennis",
  SPORTS = "sports",
}

export enum NotificationType {
  SMS = "sms",
  EMAIL = "email",
  PUSH = "push",
}

export enum APIStatus {
  SUCCESS = "success",
  ERROR = "error",
  OK = "ok",
}

export enum MatchType {
  COMPETITIVE = "competitive",
  FRIENDLY = "friendly",
}
export enum TIMEFRAME {
  DAILY = "daily",
  WEEKLY = "weekly",
  CUSTOM = "custom",
}
export enum BookingType {
  BOOKING = "booking",
  COACHING = "coaching",
  TOURNAMENT = "tournament",
}
export enum MatchPreference {
  SINGLES = "singles",
  DOUBLES = "doubles",
}
export enum BookingStatus {
  SLOT_BLOCKED = "slot-blocked",
  CONFIRMED = "confirmed",
  CANCELLED = "cancelled",
  FAILED = "failed",
  PENDING = "pending",
}

//SCHEDULED, INPROGRESS, and COMPLETED are filtered by currentStatus; all others use status.
export enum BookingFilterStatus {
  //SLOT_BLOCKED = "slot-blocked",
  //CONFIRMED = "confirmed",
  COMPLETED = 'completed',
  SCHEDULED = 'scheduled',
  INPROGRESS = 'inprogress',   
  CANCELLED = "cancelled",
  FAILED = "failed",
  //PENDING = "pending", 
}

export enum LocationType {
  POINT = "Point",
}

export enum PaymentType {
  CREDIT = "credit",
  DEBIT = "debit",
}

export enum ResourcePaidFor {
  BOOKING = "booking",
  ORDER = "order",
  COACHING = "coaching",
  TOURNAMENT = "tournament",
  SUBSCRIPTION = "subscription",
}

export enum PaymentStatus {
  PENDING = "pending",
  COMPLETED = "completed",
  TIME_OUT = "time-out",
  FAILED = "failed",
  REJECTED = "rejected",
}

export enum ProductPurchaseStatus {
  CONFIRMED = "confirmed",
  COMPLETED = "completed",
  CANCELLED = "cancelled",
  FAILED = "failed",
}

export enum BookingPaymentType {
  CARD = "card",
  CASH = "cash",
  CASA = "casa",
  SUBSCRIPTION = "membership",
  GYMLIB = "gymlib",
  APPLE_PAY = 'Apple Pay'
}

export enum Type {
  PUBLIC = "public",
  PRIVATE = "private",
}

export enum SessionType {
  INDIVIDUAL = 1,
  GROUP = 2,
  FREE_TIME_SLOT = 3,
}

export enum MatchGroups {
  GROUP_A = "a",
  GROUP_B = "b",
}

export enum CouponType {
  FLAT_OFF = "flat-off",
  PERCANTAGE = "percentage",
}

export enum CouponStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export enum CouponCategory {
  FOR_SUBSCRIPTION = "eligible-for-subscription",
  FOR_NORMAL_BOOKING = "eligible-for-normal-booking",
}

export enum CouponUse {
  ELIGIBLE_FOR_EXTRAS = "renting",
  ELIGIBLE_FOR_MEMBERSHIP = "subscription",
}

export const Slots = {
  SLOTS: [
    "00:00",
    "00:30",
    "01:00",
    "01:30",
    "02:00",
    "02:30",
    "03:00",
    "03:30",
    "04:00",
    "04:30",
    "05:00",
    "05:30",
    "06:00",
    "06:30",
    "07:00",
    "07:30",
    "08:00",
    "08:30",
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
    "19:30",
    "20:00",
    "20:30",
    "21:00",
    "21:30",
    "22:00",
    "22:30",
    "23:00",
    "23:30",
  ],
};

export const dayNames = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

export enum BookingMinutes {
  THIRTY = 30,
  SIXTY = 60,
  NINETY = 90,
  ONE_TWENTY = 120,
  ONE_EIGHTY = 180,
}

export enum ActionTypes {
  CREATE = "create",
  EDIT = "edit",
}

export enum PaidOptions {
  UNPAID = "Unpaid",
  PAID = "Paid",
}

export const coachingLabels = [
  { label: "1-3", id: 3 },
  { label: "3-5", id: 5 },
  { label: "5-7", id: 7 },
  { label: "7-10", id: 10 },
];

export const tournamentLevels = [
  { label: "1-3", id: "1-3" },
  { label: "3-5", id: "3-5" },
  { label: "5-7", id: "5-7" },
  { label: "7-10", id: "7-10" },
];

export const cycleTypes = [
  { label: "Monthly", id: 1 },
  { label: "Yearly", id: 2 },
];

export const categoryLevel = [
  { label: "Beginner" },
  { label: "Improver" },
  { label: "Intermediate" },
  { label: "Advance" },
];

export const tournamentType = [
  { label: "P25" },
  { label: "P50" },
  { label: "P75" },
  { label: "P100" },
  { label: "P150" },
  { label: "P200" },
  { label: "P250" },
  { label: "P500" },
  { label: "P1000" },
];

export enum categoryBySex {
  MALE = "male",
  FEMALE = "female",
  OTHERS = "others",
  ALL = "all",
  MIXED = "mixed",
}

export const yesOrNo = [
  { label: "Yes", value: "1" },
  { label: "No", value: "0" },
];

export const priorities = Array.from({ length: 8 }, (_, index) => ({
  label: `Priority ${index + 1}`,
  value: index + 1,
}));

export const positions = Array.from({ length: 4 }, (_, index) => ({
  label: `Position ${index + 1}`,
  value: index + 1,
}));

export const maxDays = Array.from({ length: 30 }, (_, index) => ({
  label: index + 1,
  value: index + 1,
}));

export const spots = (n = 30) => {
  return Array.from({ length: n }, (_, index) => ({
    label: `${index + 1}`,
    value: index + 1,
  }));
};

export const groups = [
  { label: "Group A", value: "a" },
  { label: "Group B", value: "b" },
];

export const tagTypes = [
  { name: "Coaching product", _id: "coaching-product" },
  { name: "Membership", _id: "membership" },
];

export const statusList = [
  { label: "Active", id: "active", value: "active" },
  { label: "Inactive", id: "inactive", value: "inactive" },
];

export const courtTypes = [
  { label: "Indoor", value: "1" },
  { label: "Outdoor", value: "0" },
];

export const types = [
  { label: "Private Session", id: "0" },
  // { label: "Group Session", id: "1" },
];

export const stockTypes = [
  { label: "In Stock", id: "1" },
  { label: "Out of Stock", id: "0" },
];

export const vatOptions = [
  { label: "Yes", id: "1" },
  { label: "No", id: "0" },
];

export const paymentTypes = [
  { label: "Cash", id: "cash" },
  { label: "Subscription", id: "1" },
];

export const tournamentVisibility = [
  { label: "Private", id: "private" },
  { label: "Public", id: "public" },
];

export const enrolmentType = [
  { label: "Doubles", id: "Doubles" },
  { label: "Singles", id: "Singles" },
];

export const features = [
  "Welcome Pack",
  "Human Resources",
  "Beverages",
  "Catering",
  "Advertising Material",
  "Furniture",
  "Audiovisual Material",
  "Cleaning and security service",
];

export enum levelRestriction {
  YES = "Yes",
  NO = "No",
}

/**
 * Handle time format to display in HH:MM Am|PM
 * @function
 * @returns options
 */
export const options: Intl.DateTimeFormatOptions = {
  hour: "numeric",
  minute: "numeric",
  hour12: true, // Use 12-hour clock format
};

export enum TournamentStatus {
  PUBLISHED = "published",
  CONFIRMED = "confirmed",
  COMPLETED = "completed",
  CANCELLED = "cancelled",
  FAILED = "failed",
  PENDING = "pending",
  INPROGRESS = "inprogress",
}

export const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
];

export const twitterBaseURL = "https://twitter.com/intent/tweet?url=";
export const instagramBaseURL = "https://www.instagram.com/?url=";

export const policyHours = [12, 24, 48, 72, 84, 96, 108, 120, 132, 144];
export const settingMinHours = [4, 8, 12, 16, 20, 24, 48, 72];
export const courtSettingMinHours = [
  "0",
  1,
  2,
  3,
  4,
  8,
  12,
  16,
  20,
  24,
  48,
  72,
];
export const minBookingHours = [
  2,
  3,
  4,
  8,
  12,
  16,
  20,
  24
];
export const settingCancellationHours = [4, 8, 12, 16, 72];

export const endUsers = "End Users";
export const adminUsers = "Admin Users";
export const feeds = "Feeds";
export const coachingManagement = "Coaching Management";
export const club = "Club";
export const booking = "Booking";
export const users = "Users";
export const tournaments = "Tournaments";
export const tags = "Tags";
export const apparelList = "Apparel List";
export const userPurchaseList = "User Purchase List";
export const membershipProducts = "Membership Products";
export const userMemberships = "User Memberships";
export const coachingProducts = "Coaching Products";
export const coupons = "Coupons";
export const services = "Services";
export const sessions = "Sessions";
export const transactions = "Transactions";

export const currency = {
  euro: "€",
};

export const FEED_ITEMS_LIST = "feedItemsList";
export const FEED_ITEM_COMMENTS_LIST = "feedItemCommentsList";
export const FeedAttachmentSliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
};

export const FeedEditModalAttachmentSliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0, // Add this line
  adaptiveHeight: true, // Add this line
};

export const genderArray = [
  {
    genderType: "male",
    genderName: "Male",
  },
  {
    genderType: "female",
    genderName: "Female",
  },
  {
    genderType: "others",
    genderName: "Others",
  },
  {
    genderType: "all",
    genderName: "All",
  },
  {
    genderType: "mixed",
    genderName: "Mixed",
  },
];

export const AdminSessionTypes = [
  {
    value: 1,
    label: "Individual Session",
    backgroundColor: "#18255A",
    borderColor: "#2D9CDB",
  },
  // {
  //   value: 2,
  //   label: "Group Session",
  //   backgroundColor: "#18255A",
  //   borderColor: "#826AF9",
  // },
  {
    value: 3,
    label: "Free Time Slot",
    backgroundColor: "#0B7337",
    borderColor: "#27AE60",
    fontColor: "#FFF",
  },
];

export const BookingPaymentStatus = [
  {
    label: "Refund completed",
    value: "refund-completed",
  },
  {
    label: "Pending",
    value: "pending",
  },
];

export enum TypeOfBooking {
  COURT = "court",
  SPOT = "spot",
}
