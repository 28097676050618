import React, { useState, useEffect } from 'react';
import MainContainer from '../../shared/components/MainContainer';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { fetchTransactionsList, resetData } from './transactionsApiService';
import { RootState, AppDispatch } from '../../redux/store';
import { BOOKING_MANAGEMENT } from '../../config/API.constants';
import { useTranslation } from 'react-i18next';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { Chip } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import StatusChangeModal from '../../shared/components/StatusChangeModal';
import { ActionTypes, BookingStatus } from '../../config/constants';
import { GridValueGetterParams } from '@mui/x-data-grid';
import { DEFAULT_PAGESIZE, Type } from 'app/config/constants';
import { urlQueryToObj } from 'app/shared/util/buildQuery';
import { useLocationQuery, useLocationQuerySet } from 'app/shared/util/useLocationQuery';
import { useNavigate } from 'react-router-dom';
import { getStatusColor } from 'app/shared/util/StatusColor';
import { getStatusLabel } from 'app/shared/util/ToCamelCase';
import { formatDate, getStartAndEndOfTime } from "app/shared/util/dateHelpers";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs from "dayjs";

dayjs.extend(utc);
dayjs.extend(timezone);

const bookingDetailAPI = BOOKING_MANAGEMENT.API_END_POINTS.GET_BOOKING_DETAIL;

const Transactions: React.FC = () => {
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();
	const searchParams = useLocationQuery();
	const setQuery = useLocationQuerySet();
	const loading = useSelector((state: RootState) => state.transactions.loading);
	const error = useSelector((state: RootState) => state.transactions.error);
	const transactionsData: any = useSelector((state: RootState) => state.transactions.data);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isChangeStatusModalOpen, setIsChangeStatusModalOpen] = useState(false);
	const [newFormData, setNewFormData] = useState<any>([]);
	const [currentPage] = React.useState(1);
	const [pageSize] = React.useState(1000);
	const [changedRow, setChangedRow] = useState({ id: '' });
	const { t } = useTranslation();

	const fetchTransactionsListWithParams = (newParams = {}) => {
		let query: any = urlQueryToObj(window.location.search);

		query = {
			...query,
			...newParams,
		};

		if (!query?.viewMode) {
			query.viewMode = 'today';
		}

		let fDate;
		let tDate;

		if (query.viewMode === 'today') {
			fDate = moment().format('YYYY-MM-DD');
			tDate = moment(fDate).add(1, 'day').format('YYYY-MM-DD');
		} else if (query.viewMode === 'viewAll' && query?.fromDate && query?.toDate) {
			fDate = moment(query?.fromDate).format('YYYY-MM-DD');
			tDate = moment(query?.toDate).add(1, 'day').format('YYYY-MM-DD');
		}

		if (fDate && tDate) {
			setQuery({
				...query,
				fromDate: fDate,
				toDate: query?.toDate || fDate,
			});
			query.fromDate = `${fDate}T00:00:00.000Z`;
			query.toDate = `${tDate}T00:00:00.000Z`;
		} else {
			delete query.fromDate;
			delete query.toDate;
			setQuery({
				...query,
			});
		}

		dispatch(
			fetchTransactionsList({
				...query,
				pageSize: query.pageSize || DEFAULT_PAGESIZE,
				page: query.page || 1,
				sort: query.sort || 'createdAt|DESC',
				...(query?.filter_isPublic && {
					filter_isPublic: query?.filter_isPublic === Type.PUBLIC
				})
			})
		);
	};

	useEffect(() => {
		fetchTransactionsListWithParams();
		return () => {
			dispatch(resetData());
		};
	}, []);

	if (error) {
		return <div>Error: {error}</div>;
	}

	const handlePageChange = (paginationModel: any) => {
		setQuery({ ...searchParams, ...paginationModel });
		fetchTransactionsListWithParams();
	};

	const changeBookingViewMode = () => {
		let query: any = urlQueryToObj(window.location.search);
		let viewMode = query?.viewMode === 'today' ? 'viewAll' : 'today';
		delete query.fromDate;
		delete query.toDate;
		setQuery({ ...query, viewMode, page: 1 });
		setTimeout(() => {
			fetchTransactionsListWithParams({ ...query, viewMode, page: 1 });
		});
	};

	/**
	 * Handle get the action column options
	 * @function
	 * @returns the menu with the options to edit, activate or deactivate based on the status
	 */

	const handleCellClick = (params: any) => {
		if (params.field !== 'action' && params.field !== '__check__') {
			navigate(`/bookings/${params?.row?._id}`);
		}
	};

	/**
	 * Handle closing the modal window
	 * @function
	 * @returns change status state with the updated values
	 */
	const handleChangeStatusCloseModal = () => {
		setIsChangeStatusModalOpen(false);
	};

	/**
	 * Handle closing the menu close
	 * @function
	 * @returns change status state with the updated values
	 */
	const handleMenuClose = () => {
		setIsChangeStatusModalOpen(false);
	};

	const handleEditClick = async (row: any) => {
		// Perform edit action
		if (newFormData) {
			handleOpenModal();
		}
	};

	const handleCancelClick = (row: any) => {
		// Handle activate action
		handleMenuClose();
		setChangedRow({ id: row?._id });
    setNewFormData(row);
		setIsChangeStatusModalOpen(true);
	};

	/**
	 * Handle opening the modal window
	 * @function
	 * @returns modal open status state with the updated values
	 */
	const handleOpenModal = () => {
		setIsModalOpen(true);
	};

	/**
	 * Handle closing the modal window
	 * @function
	 * @returns modal oepn status state with the updated values
	 */
	const handleCloseModal = () => {
		setIsModalOpen(false);
	};

	const refreshBookingsGrid = async () => {
		fetchTransactionsListWithParams();
	};

	function calculateBalance(params: any) {
		const { row } = params;
		// if (row?.typeOfBooking === 'spot') {
		// 	const balance = row?.totalAmount - row?.paidAmount;
		// 	return balance > 0 ? `€ ${balance.toFixed(2)}` : 0;
		// }

		// if (row?.typeOfBooking === 'court' && validateAmount(row?.balanceAmount)) {
		// 	return row?.balanceAmount > 0 ? `€ ${row?.balanceAmount.toFixed(2)}` : 0;
		// }
		//return 0;
		return row?.balanceAmount > 0 ? `€ ${row?.balanceAmount.toFixed(2)}` : 0;
	}

	const columns = [
		{
			field: 'date',
			headerName: t('date'),
			flex: 1,
			// valueGetter: (params: GridValueGetterParams) =>
			// 	`${params?.row?.date||'NA'}`,
		},
		{
			field: 'name',
			headerName: t('name'),
			flex: 1,
			valueGetter: (params: GridValueGetterParams) => `${params?.row?.name || 'NA'} `,
		},
		{
			field: 'club',
			headerName: t('club'),
			flex: 1,
			valueGetter: (params: GridValueGetterParams) =>
				`${params?.row?.club || ''}`,
		},
		{
			field: 'startTime',
			headerName: t('bookingDate'),
			flex: 1,
			valueGetter: (params: GridValueGetterParams) => {
				return `${params?.row?.startTime}`
			},
		},
		{
			field: 'bookingInitiatedBy',
			headerName: t('bookingInitiatedBy'),
			flex: 1,
			valueGetter: (params: GridValueGetterParams) => {
				return `${params?.row?.bookingInitiatedBy}`
			},
		},
		{
			field: 'resourcePaidFor',
			headerName: t('resourcePaidFor'),
			flex: 1,
			valueGetter: (params: GridValueGetterParams) => {
				return `${params?.row?.resourcePaidFor}`
			},
		},
		{
			field: 'paymentType',
			headerName: t('paymentType'),
			flex: 1,
			valueGetter: (params: GridValueGetterParams) => {
				return `${params?.row?.paymentType}`
			},
		},
		{
			field: 'amount',
			headerName: t('amount'),
			flex: 1,
			valueGetter: (params: GridValueGetterParams) => {
				return `${params?.row?.amount}`
			},
		},
		{
			field: 'status',
			headerName: t('status'),
			flex: 1,
			valueGetter: (params: GridValueGetterParams) => {
				return `${params?.row?.status}`
			},
		},
		{
			field: 'credits',
			headerName: t('credits'),
			flex: 1,
			valueGetter: (params: GridValueGetterParams) => {
				return `${params?.row?.credits}`
			},
		},
		{
			field: 'casaCredits',
			headerName: t('casaCredits'),
			flex: 1,
			valueGetter: (params: GridValueGetterParams) => {
				return `${params?.row?.casaCredits}`
			},
		},
	];

	const pageName = `${t('transactions')}`;

	return (
		<>
			<MainContainer
				columns={columns}
				rows={transactionsData?.data?.map((x:any, key:number)=> x={...x, _id: `${key}-${x?.user}`}) || []}
				pageName={pageName}
				//btnText={btnText}
				menuName="Transactions"
				currentPage={currentPage}
				onCurrentPageChange={handlePageChange}
				pageSize={pageSize}
				loading={loading}
				totalRecords={transactionsData?.totalCount || transactionsData?.data?.length}
				meta={{
					page: parseInt(searchParams?.page) || 1,
					pageSize: parseInt(searchParams?.pageSize),
					totalCount: transactionsData?.totalCount,
				}}
				callback={() => {
					fetchTransactionsListWithParams();
				}}
				handleCellClick={handleCellClick}
				refreshGrid={refreshBookingsGrid}
				checkboxSelection={true}
				changeBookingViewMode={changeBookingViewMode}
				bookingViewMode={searchParams?.viewMode || 'today'}
			/>
		</>
	);
};

export default Transactions;
